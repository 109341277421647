<spinner-overlay id="spinner"></spinner-overlay>
<div #printArea id="printdiv">
  <layout-navbar></layout-navbar>
  <div class="app-body">
    <layout-sidebar></layout-sidebar>
    <!-- Main content -->
    <main class="main">
      <!-- Breadcrumb -->
      <layout-breadcrumb *ngIf="showBreadcrumb$ | async"></layout-breadcrumb>
      <app-filter-bar *ngIf="showFilterBar$ | async"></app-filter-bar>
      <div id="main-content" *ngIf="layoutHelper.sideNavToggleAction$ | async as toggleStatus"
        [ngClass] = "(showFilterBar$ | async) ? 'content-container' : 'content-container-no-filter'">
          <!-- Router Content -->
          <div [@contentSlide]="toggleStatus" class="router-container">
            <div class="content-divider" *ngIf="showFilterBar$ | async"></div>
            <router-outlet></router-outlet>
          </div>
      </div>
    </main>
  </div>
  <layout-footer></layout-footer>
</div>
