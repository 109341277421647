import { Injectable } from '@angular/core';
import { LayoutTranslationConfig } from '../../../_config/translation/layout/layout.translation.config';
@Injectable()
export class LayoutTranslationService {

  layoutTranslationConfig = new LayoutTranslationConfig;

  getLayoutTranslation(val: string, locale: string) {

    if (locale === 'en') {
      return val;
    }
    
    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.layoutTranslationConfig.layoutTranslations[locale][value];
  }

  getNavLabelTranslation(val: string, locale: string) {

    if (locale === 'en') {
      return val;
    }

    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.layoutTranslationConfig.navLabelTranslations[locale][val];
  }
}

