import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from './_shared/services/appInsights/appInsights.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appInsights: AppInsightsService,
    private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          let customErrorDetails: any = {
            url: request.url,
            method: request.method,
            headers: this.sanitizeHeaders(request.headers),
            errorStatus: error.status,
          };

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Client-Side Error: ${error.error.message}`;
            customErrorDetails.errorMessage = errorMessage;

          } else {
            // server-side error
            if (error.status === 0) {
              errorMessage = `Network Error: The request failed to connect. Please check your network or CORS settings.`;
            } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            console.log('Error: ', error);

            // Handle FluentValidation messages
            if (error.status === 400 && error.error?.errors) {
              errorMessage = `Validation Error: ${JSON.stringify(error.error.errors)}`;
              customErrorDetails.validationErrors = error.error.errors;
            }

            customErrorDetails.errorResponse = error.error;
          }

          this.appInsights.trackException(new Error(errorMessage), {
            props: customErrorDetails
          });

          this.appInsights.trackException(error);
          console.log(errorMessage);

          if (error.status === 401) {
            // handle unauthorized
            localStorage.removeItem('user');
            !!environment.loginRedirectUrl ? window.location.href = environment.loginRedirectUrl : this.router.navigateByUrl('/login');
          }

          return throwError(() => new Error(errorMessage));

        })
      );
  }

  private sanitizeHeaders(headers: HttpHeaders): { [key: string]: string } {
    const sanitizedHeaders: { [key: string]: string } = {};
    headers.keys().forEach(key => {
      if (key.toLowerCase() !== 'authorization') {
        sanitizedHeaders[key] = headers.get(key) || '';
      } else {
        sanitizedHeaders[key] = '****';
      }
    });
    return sanitizedHeaders;
  }
}
