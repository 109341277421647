<h2 mat-dialog-title>
  Lead Details
  <!--<i class="fa fa-close close-icon pull-right" (click)="closeModal()"></i>-->
</h2>
<mat-dialog-content>
  <ng-container *ngIf="lead$ | async as lead">
    <table class="table table-striped">
      <tbody *ngIf="lead">
        <tr>
          <td><b>Lead Key</b></td>
          <td>{{lead.leadKey}}</td>
          <td><b>First Name</b></td>
          <td>{{lead.firstName}}</td>
        </tr>
        <tr>
          <td><b>Lead Id</b></td>
          <td>{{lead.leadId}}</td>
          <td><b>Last Name</b></td>
          <td>{{lead.lastName}}</td>
        </tr>
        <tr>
          <td><b>Lead Received</b></td>
          <td>{{lead.created | date:'M/d/yyyy h:mm a'}}</td>
          <td><b>Email Address</b></td>
          <td>{{lead.emailAddress}}</td>
        </tr>
        <tr>
          <td><b>Source</b></td>
          <td>{{this.reportConfig.useSourceMask ? lead.maskedSource : lead.sourceName}}</td>
          <td><b>Address 1</b></td>
          <td>{{lead.address1}}</td>
        </tr>
        <tr>
          <td><b>{{reportConfig.dealerLabel || 'Dealer'}} Code</b></td>
          <td>{{lead.dealerCode}}</td>
          <td><b>Address 2</b></td>
          <td>{{lead.address2}}</td>
        </tr>
        <tr>
          <td><b>{{reportConfig.dealerLabel || 'Dealer'}} Name</b></td>
          <td>{{lead.dealerName}}</td>
          <td><b>City</b></td>
          <td>{{lead.city}}</td>
        </tr>
        <tr>
          <td><b>Model</b></td>
          <td>{{lead.vehicleModel}}</td>
          <td><b>State</b></td>
          <td>{{lead.state}}</td>
        </tr>
        <tr>
          <td><b>Original Model</b></td>
          <td>{{lead.originalModel}}</td>
          <td><b>Zip Code</b></td>
          <td>{{lead.zipCode}}</td>
        </tr>
        <tr>
          <td><b>Live Date</b></td>
          <td><span *ngIf="lead.liveDate">{{lead.liveDate | date:'M/d/yyyy h:mm a'}}</span></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><b>Response Date</b></td>
          <td><span *ngIf="lead.responded">{{lead.responded | date:'M/d/yyyy h:mm a'}}</span></td>
          <td><b>Work Phone</b></td>
          <td>{{lead.workPhone}}</td>
        </tr>
        <tr>
          <td><b>Response Time</b></td>
          <td><span *ngIf="lead.responded">{{timeToRespond(lead.timeToRespond)}}</span></td>
          <td><b>Home Phone</b></td>
          <td>{{lead.homePhone}}</td>
        </tr>
        <tr>
          <td><b>Sale?</b></td>
          <td>{{sale(lead)}}</td>
          <td><b>Mobile Phone</b></td>
          <td>{{lead.mobilePhone}}</td>
        </tr>
        <tr>
          <td><b>Same {{reportConfig.dealerLabel || 'Dealer'}}?</b></td>
          <td>{{sameDealer(lead)}}</td>
          <td><b>Lead Days to Sale</b></td>
          <td>{{lead.timeToClose}}</td>
        </tr>
        <tr>
          <td><b>Sale Date</b></td>
          <td>{{lead.saleDate}}</td>
          <td><b>Sale VIN</b></td>
          <td>{{lead.saleVin}}</td>
        </tr>
      </tbody>
    </table>

    <mat-divider></mat-divider>
    <ng-container *ngIf="!excludeFromTemplate?.includes('customerComments')">
      <div class="row" style="margin-top: 20px">
        <div class="col-12">
          <h3 class="pull-left">Customer Comments</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12" style="max-width: 725px; white-space: pre-wrap; max-height: 400px; overflow-y: auto">
          {{lead.customerComments}}
        </div>
      </div>
      <br>
      <mat-divider></mat-divider>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="leadDispositions$ | async as leadDispositions">
    <ng-container *ngIf="leadDispositions.length > 0">
      <div class="row" style="margin-top: 20px">
        <div class="col-12">
          <h3 class="pull-left">Lead Activity</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead class="report-table-head">
              <tr>
                <th>Activity</th>
                <th>CRM</th>
                <th>Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody *ngIf="leadDispositions">
              <tr *ngFor="let disposition of leadDispositions" class="center">
                <td>{{disposition.activityName}}</td>
                <td>{{disposition.crmName}}</td>
                <td>{{disposition.activityDate | date:'M/d/yyyy h:mm a'}}</td>
                <td>{{disposition.activityDetails}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
