import { ILeadTransactionsConfig, IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';

export class LeadTransactionsConfig implements ILeadTransactionsConfig {

    public dealerLabel: string = 'Dealer';
    public maskSameDealerSales: boolean = true;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
    public excludeFromLeadDetailsTemplate: string[] = [];

}
