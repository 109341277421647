import * as Models from '../../models/models-index';
import { Filter, FilterTypes } from '../filter.model';
import { IFilterTreeNode } from '../../sd-filter-panel-module/types/types';
import { IFilterModel } from '../../models/models-index';

export class FilterOptions {
  includeAdvanced: boolean;
  includeLookback: boolean;
  includeMOM: boolean;
  includeTrend: boolean;
  includeYOY: boolean;
}

export class ReportFilters {
  reportName: string;
  filters: Filter[];
}

export interface FilterState {
  filters: ReportFilters[];
  lockedFilters: Filter[];
  explicitUnlockedFilters: string[];
  options: FilterOptions;
}

export const initialFilterState: FilterState = {
  filters: [],
  lockedFilters: [],
  explicitUnlockedFilters: [],
  options: {
    includeAdvanced: false,
    includeLookback: false,
    includeMOM: false,
    includeTrend: false,
    includeYOY: false
   }
};
