import {
    IDefaultTableColumnConfig,
    IDigAdChannelConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdChannelConfig implements IDigAdChannelConfig {
    reportTitle: string = 'Digital Advertising Channel Performance';
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.digAdChannelSummaryHelp,
        helpTextTitle: 'Channel Summary',
        exportName: 'Digital Advertising Channel Performance - Summary Data',
        metricDisplayModes: ['MOM', 'YOY']
    };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.digitalAdvertisingMetrics.impressions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.impressions.name,
                propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.clicks.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clicks.name,
                propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
                displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
                propertyName: Enums.digitalAdvertisingMetrics.actualCostPerClick.nameKey,
                metricFormatKey: constants.formatKeys.roundToHundreth
            },
            {
                id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
                displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
                propertyName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
                displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
                propertyName: Enums.digitalAdvertisingMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            { 
                id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
                displayName: Enums.digitalAdvertisingMetrics.uniqueVisitors.name,
                propertyName: 'taggingUniqueVisitors',
                metricFormatKey: constants.formatKeys.roundToInteger
            }, 
            {
                id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
                propertyName: 'taggingFormSubmissions',
                metricFormatKey: constants.formatKeys.roundToInteger
            },
            {
                id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
                displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
                propertyName: Enums.digitalAdvertisingMetrics.phoneCalls.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            }
        ],
        childMetrics: [
            {
                id: Enums.digitalAdvertisingChannels.allChannels.channelId,
                displayName: Enums.digitalAdvertisingChannels.allChannels.channelName,
                propertyName: Enums.digitalAdvertisingChannels.allChannels.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: Enums.digitalAdvertisingChannels.search.channelId,
                displayName: Enums.digitalAdvertisingChannels.search.channelName,
                propertyName: Enums.digitalAdvertisingChannels.search.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: Enums.digitalAdvertisingChannels.display.channelId,
                displayName: Enums.digitalAdvertisingChannels.display.channelName,
                propertyName: Enums.digitalAdvertisingChannels.display.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: Enums.digitalAdvertisingChannels.video.channelId,
                displayName: Enums.digitalAdvertisingChannels.video.channelName,
                propertyName: Enums.digitalAdvertisingChannels.video.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: Enums.digitalAdvertisingChannels.social.channelId,
                displayName: Enums.digitalAdvertisingChannels.social.channelName,
                propertyName: Enums.digitalAdvertisingChannels.social.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: Enums.digitalAdvertisingChannels.email.channelId,
                displayName: Enums.digitalAdvertisingChannels.email.channelName,
                propertyName: Enums.digitalAdvertisingChannels.email.channelName,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],

      defaultChildrenIds: [Enums.digitalAdvertisingChannels.allChannels.channelId, Enums.digitalAdvertisingChannels.allChannels.channelId],
        defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
        metricOneColorHexCode: '#000',
        metricTwoColorHexCode: '#737373'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Provider',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // Impressions
        {
            show: true,
            header: 'Impressions',
            columnDef: 'impressions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Impressions',
            columnDef: 'impressionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions MOM',
            columnDef: 'impressionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Impressions',
            columnDef: 'impressionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions YOY',
            columnDef: 'impressionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Clicks
        {
            show: true,
            header: 'Clicks',
            columnDef: 'clicks',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Clicks',
            columnDef: 'clicksPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks MOM',
            columnDef: 'clicksMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Clicks',
            columnDef: 'clicksPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks YOY',
            columnDef: 'clicksYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // CTR
        {
            show: true,
            header: 'Click Through Rate',
            columnDef: 'clickThroughRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Click Through Rate',
            columnDef: 'clickThroughRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate MOM',
            columnDef: 'clickThroughRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Click Through Rate',
            columnDef: 'clickThroughRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate YOY',
            columnDef: 'clickThroughRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // CPC
        {
            show: true,
            header: 'Cost Per Click',
            columnDef: 'actualCostPerClick',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Cost Per Click',
            columnDef: 'actualCostPerClickPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Cost Per Click MOM',
            columnDef: 'actualCostPerClickClickMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Cost Per Click',
            columnDef: 'actualCostPerClickPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Cost Per Click YOY',
            columnDef: 'actualCostPerClickYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // CPM
        {
            show: true,
            header: 'Cost Per Impression',
            columnDef: 'actualCostPerImpression',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Cost Per Impression',
            columnDef: 'actualCostPerImpressionPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Cost Per Impression MOM',
            columnDef: 'actualCostPerImpressionImpressionMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Cost Per Impression',
            columnDef: 'actualCostPerImpressionPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.currency, clickable: false
        },
        {
            show: false,
            header: 'Cost Per Impression YOY',
            columnDef: 'actualCostPerImpressionYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Total Site Visits
        {
            show: true,
            header: 'Total Website Visits',
            columnDef: 'totalVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Total Website Visits',
            columnDef: 'totalVisitsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Website Visits MOM',
            columnDef: 'totalVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Total Website Visits',
            columnDef: 'totalVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Website Visits YOY',
            columnDef: 'totalVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Unique Site Visits
        {
            show: true,
            header: 'Unique Visitors',
            columnDef: 'taggingUniqueVisitors',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Unique Visitors',
            columnDef: 'taggingUniqueVisitorsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visitors MOM',
            columnDef: 'taggingUniqueVisitorsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Unique Visitors',
            columnDef: 'taggingUniqueVisitorsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visitors YOY',
            columnDef: 'taggingUniqueVisitorsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Form Submissions
        {
            show: true,
            header: 'Form Submissions',
            columnDef: 'taggingFormSubmissions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Form Submissions',
            columnDef: 'taggingFormSubmissionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions MOM',
            columnDef: 'taggingFormSubmissionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Form Submissions',
            columnDef: 'taggingFormSubmissionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions YOY',
            columnDef: 'taggingFormSubmissionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Phone Calls
        {
            show: true,
            header: 'Phone Calls',
            columnDef: 'phoneCalls',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Phone Calls',
            columnDef: 'phoneCallsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Phone Calls MOM',
            columnDef: 'phoneCallsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Phone Calls',
            columnDef: 'phoneCallsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Phone Calls YOY',
            columnDef: 'phoneCallsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Conversion Rate
        {
            show: true,
            header: 'Conversion Rate',
            columnDef: 'conversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Conversion Rate',
            columnDef: 'conversionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate MOM',
            columnDef: 'conversionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Conversion Rate',
            columnDef: 'conversionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate YOY',
            columnDef: 'conversionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Bounce Rate
        {
            show: true,
            header: 'Bounce Rate',
            columnDef: 'bounceRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Bounce Rate',
            columnDef: 'bounceRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate MOM',
            columnDef: 'bounceRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Bounce Rate',
            columnDef: 'bounceRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate YOY',
            columnDef: 'bounceRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];

}
