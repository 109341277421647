import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { LayoutTranslationService } from '../locale/translation/layout-translation.service';

@Injectable()
@Pipe({ name: 'translatelayout', pure: true })
export class TranslateLayoutPipe implements PipeTransform {
  constructor(private translationService: LayoutTranslationService) {}
  transform(val: any, locale?: string): string {
    if (locale === 'en') {
      return val;
    }
    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.translationService.getLayoutTranslation(value, locale ?? "en");
  }
}
