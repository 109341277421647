import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'website-metric-type': { displayName: 'Metric', name: 'website-metric-type' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Chat
        'ChatOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.chatOverview,
            filters: ['org', 'device-type'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 2]
        },
        // DigAd
        'DigitalAdvertisingOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingOverview,
            filters: ['org'],
            defaults: ['org'] ,
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingScorecard,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingSummary,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingProvider': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingProvider,
            filters: ['org', 'channel'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingChannel': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingChannel,
            filters: ['org', 'digad-provider'],
            defaults: ['org'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteAfterSales': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteAfterSales,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteProviderScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteProviderScorecard, // metric only
            filters: [],
            defaults: [],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteVehicleSummary,
            filters: [
                'org', 'website-provider', 'device-type'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 2]
        }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'channel': {
            type: 'channel',
            available: [ 2, 4, 5, 6, 8 ],
            selected: [
                { value: 2, display: 'Paid Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 6, display: 'Paid Email' },
                { value: 8, display: 'Paid Social' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [12, 15, 17, 19, 24],
            selected: [
                { value: 12, display: 'CarNow' },
                { value: 15, display: 'ActivEngage' },
                { value: 17, display: 'Gubagoo' },
                { value: 19, display: 'Podium' },
                { value: 24, display: 'Conversations' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
            type: 'digad-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        }
    };
}
