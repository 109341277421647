import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SdFilterPanelModule } from './sd-filter-panel-module/sd-filter-panel.module';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MONACO_PATH, MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MetaReducer } from '@ngrx/store';
import { storageMetaReducer } from '../_store/storage.metareducer';
import { MatDialogModule } from '@angular/material/dialog';

// components
import { ActivityGuageChartComponent } from './components/activity-guage-chart/activity-guage-chart.component';
import { BarChartCardComponent } from './components/bar-chart-card/bar-chart-card.component';
import { BarChartCardV2Component } from './components/bar-chart-card-v2/bar-chart-card-v2.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardHeaderV2Component } from './components/card-header-v2/card-header-v2.component';
import { CardHeaderSelectComponent } from './components/card/card-header-select.component';
import { CardComponent } from './components/card/card.component';
import { CombinedDataTableComponent } from './dataTable/combinedDataTable.component';
import { FullScreenOverlayComponent } from './components/full-screen-overlay/full-screen-overlay.component';
import { HierarchyDataTableComponent } from './dataTable/hierarchyDataTable.component';
import { PagedSortedDataTableComponent } from './dataTable/pagedSortedDataTable.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { ProviderScorecardBillboardComponent } from './billboard/provider-scorecard-billboard.component';
import { ProviderMultiMetricScorecardBillboardComponent } from './billboard/provider-multi-metric-scorecard-billboard.component';
import { ScorecardBillboardComponent } from './billboard/scorecardBillboard.component';
import { SingleAxisAreaChartComponent } from './components/single-axis-area-chart/single-axis-area-chart.component';
import { SolidGuageChartComponent } from './components/solid-guage-chart/solid-guage-chart.component';
import { TrendDataTableComponent } from './dataTable/trendDataTable.component';
import { ChartComponent } from './components/chart/chart.component';
import { SdGoogleChartComponent } from './components/google-chart/sd-google-chart.component';
import { FilterBarComponent } from './filter/filter-bar.component';
import { HorizontalFunnelComponent } from './components/horizontal-funnel/horizontal-funnel.component';
import { LegacyScorecardBillboardComponent } from './billboard/legacyScorecardBillboard.component';
import { LeadsOverviewBillboardComponent } from './billboard/leadsOverviewBillboard.component';
import { OverviewBillboardComponent } from './billboard/overviewBillboard.component';
import { OverviewMiniBillboardComponent } from './billboard/overview-mini-billboard.component';
import { OverviewBillboardLineChartComponent } from './billboard/overviewBillboardLineChart.component';
import { OverviewBillboardV2Component } from './billboard/overview-billboard-v2/overview-billboard-v2.component';
import { OverviewFullWidthPanelComponent } from './components/panels/overview-full-width-panel/overview-full-width-panel.component';
import { KpiBarChartComponent } from './components/panel-elements/kpi-bar-chart/kpi-bar-chart.component';
import { KpiMetricComponent } from './components/panel-elements/kpi-metric/kpi-metric.component';
import { KpiSparkAreaFillComponent } from './components/panel-elements/kpi-spark-area-fill/kpi-spark-area-fill.component';
import { StackedTwoKpiMetricComponent } from './components/panel-elements/stacked-two-kpi-metric/stacked-two-kpi-metric.component';
import { KpiSemiCircleDonutComponent } from './components/panel-elements/kpi-semi-circle-donut/kpi-semi-circle-donut.component';
import { StackedBarChartPercentComponent } from './components/panel-elements/stacked-bar-chart-percent/stacked-bar-chart-percent.component';
import { DataPanelComponent } from './components/panel-elements/data-panel/data-panel.component';
import { GhostPanelFullWidthComponent } from './components/panels/ghost-panel-full-width/ghost-panel-full-width.component';
import { KpiMetricDetailedComponent } from './components/panel-elements/kpi-metric-detailed/kpi-metric-detailed.component';
import { HorizontalBarByDimensionComponent } from './components/panel-elements/horizontal-bar-by-dimension/horizontal-bar-by-dimension.component';
import { KpiPieChartComponent } from './components/panel-elements/kpi-pie-chart/kpi-pie-chart.component';
import { KpiMultiLineChartComponent } from './components/panel-elements/kpi-multi-line-chart/kpi-multi-line-chart.component';
import { HierarchyDataTableV5Component } from './components/panel-elements/hierarchy-data-table/hierarchy-data-table.component';
import { FullWidthDataPanelComponent } from './components/panels/full-width-data-panel/full-width-data-panel.component';
import { GhostPanelComponent } from './components/panels/ghost-panel/ghost-panel.component';
import { KpiSimplePieChartComponent } from './components/panel-elements/kpi-simple-pie-chart/kpi-simple-pie-chart.component';
import { IndexedVisualizationPanelComponent } from './components/panels/indexed-visualization-panel/indexed-visualization-panel.component';
import { RowPanelComponent } from './components/panels/row-panel/row-panel.component';
import { StackedColumnChartComponent } from './components/panel-elements/stacked-column-chart/stacked-column-chart.component';

import * as PanelElements from './components/panel-elements/panel-elements-index';


// Directives
import { RestrictElementDirective, ToggleExpandoCardDirective } from './directives/directives-index';
import { ToggleExpandoDataTableDirective } from './directives/directives-index';
import { DynamicComponentDirective } from './directives/directives-index';

// Pipes
import { AbbreviatedMetricPipe } from './pipes/abbreviatedMetric.pipe';
import { TotalPagesPipe } from './pipes/totalPages.pipe';
import { CallbackPipe } from './pipes/callback.pipe';
import { SdCurrencyPipe } from './pipes/currency.pipe';
import { LocaleStringPipe } from './pipes/localeString.pipe';
import { PagingDetailPipe } from './pipes/paging-detail.pipe';
import { DecimalNumberFormatPipe } from './pipes/decimalNumberFormat.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { NumberToStringPipe } from './pipes/numberToStringPipe';
import { DisplayMonthPipe } from './pipes/displayMonth.pipe';
import { FormatMetricPipe } from './pipes/formatMetric.pipe';
import { FormatterPipe } from './pipes/formatter.pipe';
import { RegionPipe } from './pipes/region.pipe';
import { TimeStringPipe } from './pipes/timeString.pipe';
import { TrendPipe } from './pipes/trend.pipe';
import { MinutesToHoursPipe } from './pipes/minutesToHours.pipe';
import { CurrencyPipe, DecimalPipe, PercentPipe, DatePipe } from '@angular/common';

// Services
import * as Services from '../_shared/services/services-index';
import { LocalCacheService } from '../_shared/services/cache/localCache.service';
import { FilterResolverService } from './filter/filter-resolver.service';
import { MultiLevelTrendGraphCardComponent } from './cards/multi-level-trend-graph-card.component';
import { TrendGraphCardComponent } from './cards/trend-graph-card.component';
import { DualLineChartComponent } from './components/dual-line-chart/dual-line-chart.component';
import { MultiLevelDualLineChartComponent } from './components/multi-level-dual-line-chart/multi-level-dual-line-chart.component';
import { MetricSimpleSelectComponent } from './components/metric-simple-select/metric-simple-select.component';
import { MetricComplexSelectComponent } from './components/metric-complex-select/metric-complex-select.component';
import { FullScreenOverlayService } from './components/full-screen-overlay/fullscreen-overlay.service';
import { ChartService } from './components/chart/chart.service';
import { TranslateBasePipe } from './pipes/translate.pipe';
import { PanelElementResolverService } from './components/panels/panel-element-resolver.service';
import { CardService } from './components/card/card.service';
import { PanelElementFormattingService } from './components/panels/panel-element-formatting.service';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { SqlEditorComponent } from './components/sql-editor/sql-editor.component';
import { ConfirmProceedModalComponent } from './modals/confirmProceedModal.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';


// filter factory meta-reducer configuration function
export function getFilterStorageMetaReducer(
    saveKeys: string[],
    localStorageKey: string,
    storageService: Services.LocalStorageService
    ): MetaReducer<any> {
      return storageMetaReducer(saveKeys, localStorageKey, storageService);
  }

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        OverlayModule,
        AngularEditorModule,
        SdFilterPanelModule,
        ChartModule,
        GoogleChartsModule,
        FormsModule,
        MonacoEditorModule,
        ReactiveFormsModule,
        MatDialogModule],
    declarations: [
        // components
        FilterBarComponent,
        FullScreenOverlayComponent,
        BarChartCardComponent,
        CardHeaderComponent,
        CardHeaderV2Component,
        CardHeaderSelectComponent,
        CardComponent,
        CombinedDataTableComponent,
        HierarchyDataTableComponent,
        PagedSortedDataTableComponent,
        TrendDataTableComponent,
        LegacyScorecardBillboardComponent,
        ProviderScorecardBillboardComponent,
        ProviderMultiMetricScorecardBillboardComponent,
        ScorecardBillboardComponent,
        SingleAxisAreaChartComponent,
        LeadsOverviewBillboardComponent,
        OverviewBillboardComponent,
        OverviewBillboardLineChartComponent,
        OverviewMiniBillboardComponent,
        ActivityGuageChartComponent,
        SolidGuageChartComponent,
        PieChartComponent,
        MultiLevelDualLineChartComponent,
        DualLineChartComponent,
        MetricSimpleSelectComponent,
        MetricComplexSelectComponent,
        MultiLevelTrendGraphCardComponent,
        TrendGraphCardComponent,
        ChartComponent,
        SdGoogleChartComponent,
        FullScreenOverlayComponent,
        BarChartCardV2Component,
        OverviewBillboardV2Component,
        HorizontalFunnelComponent,
        OverviewFullWidthPanelComponent,
        FullWidthDataPanelComponent,
        HierarchyDataTableV5Component,
        IndexedVisualizationPanelComponent,
        RowPanelComponent,
        StackedColumnChartComponent,
        JsonEditorComponent,
        SqlEditorComponent,
        TagsInputComponent,

        // Pipes
        AbbreviatedMetricPipe,
        CallbackPipe,
        DisplayMonthPipe,
        FormatMetricPipe,
        FormatterPipe,
        LocaleStringPipe,
        NumberToStringPipe,
        PagingDetailPipe,
        DecimalNumberFormatPipe,
        PercentagePipe,
        RegionPipe,
        SdCurrencyPipe,
        TimeStringPipe,
        TotalPagesPipe,
        MinutesToHoursPipe,
        TranslateBasePipe,
        TrendPipe,

        // Directives
        DynamicComponentDirective,
        ToggleExpandoCardDirective,
        ToggleExpandoDataTableDirective,
        RestrictElementDirective,

        // Elements
        PanelElements.BillboardBenchmarkComponent,
        KpiBarChartComponent,
        KpiMetricComponent,
        KpiMetricDetailedComponent,
        KpiPieChartComponent,
        KpiMultiLineChartComponent,
        KpiSemiCircleDonutComponent,
        KpiSparkAreaFillComponent,
        StackedTwoKpiMetricComponent,
        GhostPanelFullWidthComponent,
        HorizontalBarByDimensionComponent,
        StackedBarChartPercentComponent,
        DataPanelComponent,
        GhostPanelComponent,
        KpiSimplePieChartComponent,
        PanelElements.KpiDualMetricDetailedComponent,
        PanelElements.KpiSheetComponent,
        PanelElements.KpiLineComparisonChartComponent
    ],
    exports: [ CommonModule, FlexLayoutModule, AngularEditorModule, MaterialModule, OverlayModule, ChartModule, GoogleChartsModule, MonacoEditorModule,

        // components
        FilterBarComponent,
        FullScreenOverlayComponent,
        BarChartCardComponent,
        CardHeaderComponent,
        CardHeaderV2Component,
        CardHeaderSelectComponent,
        CardComponent,
        CombinedDataTableComponent,
        HierarchyDataTableComponent,
        PagedSortedDataTableComponent,
        TrendDataTableComponent,
        LegacyScorecardBillboardComponent,
        ProviderScorecardBillboardComponent,
        ProviderMultiMetricScorecardBillboardComponent,
        ScorecardBillboardComponent,
        SingleAxisAreaChartComponent,
        LeadsOverviewBillboardComponent,
        OverviewBillboardComponent,
        OverviewBillboardLineChartComponent,
        OverviewMiniBillboardComponent,
        ActivityGuageChartComponent,
        SolidGuageChartComponent,
        PieChartComponent,
        MultiLevelDualLineChartComponent,
        DualLineChartComponent,
        MetricSimpleSelectComponent,
        MetricComplexSelectComponent,
        MultiLevelTrendGraphCardComponent,
        TrendGraphCardComponent,
        ChartComponent,
        SdGoogleChartComponent,
        BarChartCardV2Component,
        OverviewBillboardV2Component,
        HorizontalFunnelComponent,
        OverviewFullWidthPanelComponent,
        FullWidthDataPanelComponent,
        HierarchyDataTableV5Component,
        JsonEditorComponent,
        SqlEditorComponent,
        TagsInputComponent,

        // Pipes
        AbbreviatedMetricPipe,
        CallbackPipe,
        DisplayMonthPipe,
        FormatMetricPipe,
        FormatterPipe,
        LocaleStringPipe,
        NumberToStringPipe,
        PagingDetailPipe,
        DecimalNumberFormatPipe,
        PercentagePipe,
        RegionPipe,
        SdCurrencyPipe,
        TimeStringPipe,
        TotalPagesPipe,
        MinutesToHoursPipe,
        TrendPipe,
        TranslateBasePipe,

        // Directives
        DynamicComponentDirective,
        ToggleExpandoCardDirective,
        ToggleExpandoDataTableDirective,
        RestrictElementDirective,

        // Elements
        KpiBarChartComponent,
        KpiMetricComponent,
        KpiMetricDetailedComponent,
        KpiPieChartComponent,
        KpiMultiLineChartComponent,
        KpiSemiCircleDonutComponent,
        KpiSparkAreaFillComponent,
        StackedTwoKpiMetricComponent,
        GhostPanelFullWidthComponent,
        HorizontalBarByDimensionComponent,
        StackedBarChartPercentComponent,
        DataPanelComponent,
        GhostPanelComponent,
        KpiSimplePieChartComponent,
        IndexedVisualizationPanelComponent,
        RowPanelComponent,
        StackedColumnChartComponent,
        PanelElements.BillboardBenchmarkComponent,
        PanelElements.KpiDualMetricDetailedComponent,
        PanelElements.KpiSheetComponent,
        PanelElements.KpiLineComparisonChartComponent
    ],
    providers: [
        AbbreviatedMetricPipe,
        CallbackPipe,
        CurrencyPipe,
        DecimalPipe,
        DisplayMonthPipe,
        FormatMetricPipe,
        FormatterPipe,
        LocaleStringPipe,
        NumberToStringPipe,
        DecimalNumberFormatPipe,
        PercentagePipe,
        PercentPipe,
        SdCurrencyPipe,
        TrendPipe,
        MinutesToHoursPipe,
        DatePipe,
        TranslateBasePipe,
        LocalCacheService,
        FullScreenOverlayService,
        PanelElementFormattingService,
        PanelElementResolverService,
        Services.SharedTranslationService,
        Services.ConfigurationService,
        Services.LocalStorageService,
        Services.FilterConfigService,
        Services.FormattingService,
        Services.HighchartsFormattingService,
        Services.OrgConfigService,
        Services.ReportConfigService,
        Services.RoleConfigService,
        Services.RoutingConfigService,
        FilterResolverService,
        {
            provide: HIGHCHARTS_MODULES,
            useFactory: () => [HighchartsMore, HighchartsSolidGauge]
        },
        ChartService,
        CardService,
        {
            provide: MONACO_PATH,
            useValue: 'https://unpkg.com/monaco-editor@0.19.3/min/vs'
        },
    ]
})
export class SharedModule { }
