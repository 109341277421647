import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, LocalCacheService, SpinnerService, ConfigurationService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import { Subscription, EMPTY, of } from 'rxjs';
import { tap, catchError, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../_store/app-state.model';
import { FilterActions } from '../_shared/filter/store';
import { AppActions } from '../_store/action-types';
import { FilterBarService } from '../_shared/filter/filter-bar.service';
import { AppSelectors } from '../_store/selector-types';

interface ILogin {
  message: string;
  userName: string;
  password: string;

}

@Component({
  templateUrl: './ssologinV2.component.html',
})

export class SsoLoginV2Component implements ILogin, OnDestroy, OnInit {
  private _subscription = new Subscription();
  message = '';
  userName = '';
  password = '';
  dealerGroup = '';
  response = '';
  roleEntity = '';
  rememberMe: boolean;
  forceLoginDisable = false;
  enums: IEnums = Enums;

  constructor(
    private route: ActivatedRoute,

    private authService: AuthenticationService,
    private router: Router,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private cache: LocalCacheService,
    private configService: ConfigurationService,
    private filterBarService: FilterBarService) {

  }

  ngOnInit() {
    this.store.dispatch(AppActions.resetAuth());
    this.store.dispatch(FilterActions.resetFilters());
    this.filterBarService.clearFiltersLoaded();

    this.route.queryParams.subscribe(params => {
      const encodedResponse = params['authData'];

      // Decode the response
      this.response = JSON.parse(atob(encodedResponse));
      this.ssoLogin();
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ssoLogin(): void {
    this.cache.purgeAllCaches();
    this.message = '';

    const authInfo = this.authService.getAuthInfo(this.response);

    this._subscription.add(
      of(authInfo).pipe(
        tap(auth => this.store.dispatch(AppActions.login({auth}))),
        switchMap(() => this.store.select(AppSelectors.selectAuthState).pipe(
          filter(auth => !!auth.auth),
      )),
        catchError(err => {
          this.spinner.hide();
          console.error(err);
          this.message = 'Unknown email/password.';
          return EMPTY;
        })
      ).subscribe(() => {
        this.spinner.hide();
        this.router.navigateByUrl(this.configService.routing.defaultRoute);
      })
    );
  }
}


