<div>
  <ng-container *ngIf="supportContent$ | async as supportContent">
    <h2 mat-dialog-title>{{'Support' | translatelayout: locale}}</h2>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <div [innerHTML]="supportContent"></div>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="closeModal()">{{'CLOSE' | translatelayout: locale}}</button>
    </div>
</ng-container>
</div>
