import { Injectable, ComponentFactoryResolver, Type } from '@angular/core';
import { HorizontalBarByDimensionComponent } from '../panel-elements/horizontal-bar-by-dimension/horizontal-bar-by-dimension.component';
import { CombinedDataTableComponent } from '../../dataTable/combinedDataTable.component';
import { ElementComponent, PanelComponent } from '../../models/report-view.models';
import * as PanelElements from '../panel-elements/panel-elements-index';
import { FullWidthDataPanelComponent } from './full-width-data-panel/full-width-data-panel.component';
import { GhostPanelFullWidthComponent } from './ghost-panel-full-width/ghost-panel-full-width.component';
import { OverviewFullWidthPanelComponent } from './overview-full-width-panel/overview-full-width-panel.component';
import { GhostPanelComponent } from './ghost-panel/ghost-panel.component';
import { IndexedVisualizationPanelComponent } from './indexed-visualization-panel/indexed-visualization-panel.component';

@Injectable()
export class PanelElementResolverService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    resolvePanelComponent(panelType: string) {
        let component: Type<PanelComponent>;

        switch (panelType) {
            case 'data-panel':
                component = FullWidthDataPanelComponent;
            case 'overview-full-width-panel':
                component = OverviewFullWidthPanelComponent;
                break;
            case 'ghost-panel-full-width':
                component = GhostPanelFullWidthComponent;
                break;
            case 'ghost-panel':
                component = GhostPanelComponent;
                break;
            case 'full-width-data-panel':
                component = FullWidthDataPanelComponent;
                break;
            case 'indexed-visualization-panel':
                component = IndexedVisualizationPanelComponent;
                break;
            default:
                component = OverviewFullWidthPanelComponent;
                break;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        return componentFactory;
    }

    resolveElementComponent(elementType: string) {
        let component: Type<ElementComponent>;

        switch (elementType) {
            case 'kpi-metric':
                component = PanelElements.KpiMetricComponent;
                break;
            case 'stacked-two-kpi-metric':
                component = PanelElements.StackedTwoKpiMetricComponent;
                break;
            case 'kpi-semi-circle-donut':
                component = PanelElements.KpiSemiCircleDonutComponent;
                break;
            case 'kpi-spark-area-fill':
                component = PanelElements.KpiSparkAreaFillComponent;
                break;
            case 'kpi-bar-chart':
                component = PanelElements.KpiBarChartComponent;
                break;
            case 'kpi-metric-detailed':
                component = PanelElements.KpiMetricDetailedComponent;
                break;
            case 'horizontal-bar-by-dimension':
                component = PanelElements.HorizontalBarByDimensionComponent;
                break;
            case 'hierarchy-data-table':
                component = PanelElements.HierarchyDataTableV5Component;
                break;
            case 'kpi-pie-chart':
                component = PanelElements.KpiPieChartComponent;
                break;
            case 'kpi-multi-line-chart':
                component = PanelElements.KpiMultiLineChartComponent;
                break;
            case 'kpi-line-comparison-chart':
                component = PanelElements.KpiLineComparisonChartComponent;
                break;
            case 'stacked-bar-chart-percent':
                component = PanelElements.StackedBarChartPercentComponent;
                break;
            case 'stacked-column-chart':
                component = PanelElements.StackedColumnChartComponent;
                break;
            case 'billboard-benchmark':
                component = PanelElements.BillboardBenchmarkComponent;
                break;
            case 'kpi-dual-metric-detailed':
                component = PanelElements.KpiDualMetricDetailedComponent;
                break;
            case 'kpi-sheet':
                component = PanelElements.KpiSheetComponent;
                break;
            default:
                break;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        return componentFactory;
    }
}
