import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Filter } from './filter.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../_store/app-state.model';
import { FilterBarService } from './filter-bar.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FilterResolverService implements Resolve<Filter[]> {
  constructor(
    private store$: Store<AppState>,
    private filterBarService: FilterBarService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Filter[]> {
    if (!route.data.reportName) {
      return of([]);
    } else {
      return this.filterBarService.getReportFilters(route.data.reportName).pipe(
        take(1)
      );
    }
  }
}
