import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { SharedTranslationService } from '../locale/translation/shared-translation.service';

@Injectable()
@Pipe({ name: 'translate', pure: true })
export class TranslateBasePipe implements PipeTransform {
  constructor(private translationService: SharedTranslationService) {}
  transform(val: any, locale?: string): string {
    //if (locale === 'en') {
    //  return val;
    //}

    return this.translationService.getLabelTranslation(val, locale);
  }
}
