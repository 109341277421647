import { Injectable } from '@angular/core';
import { LeadsTranslationConfig } from '../../../_config/translation/leads/leads.translation.config';
import { SharedTranslationConfig } from '../../../_config/translation/shared/shared.translation.config';


@Injectable()
export class LeadsTranslationService {
  leadTranslationConfig = new LeadsTranslationConfig;
  sharedTranslationConfig = new SharedTranslationConfig;

  getLeadMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    // passing in and returning default label takes away dependance on any translation file at all
    if (locale === 'en') {
      return defaultLabel;
    }

    return this.leadTranslationConfig.leadMetrics[locale][metricId] ?? '';
  }

  getLeadReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.leadTranslationConfig.leadReportTitles[locale][cleansedValue];
  }

  getLeadLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.leadTranslationConfig.leadLabels[locale][cleansedValue];
  }

  getLeadDisclaimerTranslation(value: string, timezone: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value + timezone;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '') + timezone.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.leadTranslationConfig.leadLabels[locale][cleansedValue];
  }

  getLeadChartDimension(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.leadTranslationConfig.leadChartDimensions[locale][cleansedValue] || value;
  }

}
