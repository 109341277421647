import {
    IDefaultTableColumnConfig,
    ISalesVehicleConfig,
    IMultiLevelDualAxisLineGraphConfig} from '../../../../_shared/models/models-index';

export class SalesVehicleConfig implements ISalesVehicleConfig {
    public reportTitle: string;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
