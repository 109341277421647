
import {
  ISalesOverviewConfig,
  ISalesOverviewBillboardConfig,
  IDefaultTableColumnConfig,
  ISalesOverviewKpiConfig,
  IPreviousDateRangeDisplay
} from '../../../../_shared/models/models-index';

export class SalesOverviewConfig implements ISalesOverviewConfig {
  public reportTitle: string;
  public useDefaultComparisonColumns: boolean;
  public chartSeriesColors: string[];
  public billboards: ISalesOverviewBillboardConfig[] = [];
  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [];
  public kpiDailyCardConfig: ISalesOverviewKpiConfig = null;
  public kpiMonthlyCardConfig: ISalesOverviewKpiConfig = null;
  public previousDateColumnDisplayOverrides?: IPreviousDateRangeDisplay = null;
}
