import {
    IDefaultTableColumnConfig,
    ISalesSourceConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class SalesSourceConfig implements ISalesSourceConfig {

    public useDefaultComparisonColumns: boolean = false;
    public graphConfig: IDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
