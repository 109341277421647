import {
    IDefaultTableColumnConfig,
    IDigAdProviderConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdProviderConfig implements IDigAdProviderConfig {
    reportTitle: string = 'Digital Advertising Provider Performance';
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.digAdProviderSummaryHelp,
        helpTextTitle: 'Provider Summary',
        exportName: 'Digital Advertising Provider Performance - Summary Data',
        metricDisplayModes: ['MOM', 'YOY']
    };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.digitalAdvertisingMetrics.impressions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.impressions.name,
                propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.clicks.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clicks.name,
                propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
                displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
                propertyName: Enums.digitalAdvertisingMetrics.totalVisits.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
                displayName: 'Unique Visitors',
                propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
                displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
                propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
                displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
                propertyName: Enums.digitalAdvertisingMetrics.phoneCalls.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
                displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
                propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            }
        ],
        childMetrics: [
            {
                id: Enums.digitalAdvertisingProviders.allProviders.providerId,
                displayName: Enums.digitalAdvertisingProviders.allProviders.name,
                propertyName: Enums.digitalAdvertisingProviders.allProviders.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 30,
                displayName: 'Constellation',
                propertyName: 'Constellation',
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 1,
                displayName: Enums.digitalAdvertisingProviders.dealerFire.name,
                propertyName: Enums.digitalAdvertisingProviders.dealerFire.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 2,
                displayName: Enums.digitalAdvertisingProviders.dealerInspire.name,
                propertyName: Enums.digitalAdvertisingProviders.dealerInspire.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 4,
                displayName: Enums.digitalAdvertisingProviders.dealerDotCom.name,
                propertyName: Enums.digitalAdvertisingProviders.dealerDotCom.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 3,
                displayName: Enums.digitalAdvertisingProviders.dealerOn.name,
                propertyName: Enums.digitalAdvertisingProviders.dealerOn.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 21,
                displayName: Enums.digitalAdvertisingProviders.ebizAutos.name,
                propertyName: Enums.digitalAdvertisingProviders.ebizAutos.name,
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 31,
                displayName:  'Pure Cars',
                propertyName: 'Pure Cars',
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 9,
                displayName:  'Stream',
                propertyName: 'Stream',
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
                id: 32,
                displayName:  'TeamVelocity',
                propertyName: 'TeamVelocity',
                metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],

        defaultChildrenIds: [Enums.digitalAdvertisingProviders.allProviders.providerId, Enums.digitalAdvertisingProviders.allProviders.providerId],
        defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
        metricOneColorHexCode: '#34383c',
        metricTwoColorHexCode: '#c51230'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Provider',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },
        // Impressions
        {
            show: true,
            header: 'Impressions',
            columnDef: 'impressions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Impressions',
            columnDef: 'impressionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions MOM',
            columnDef: 'impressionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Impressions',
            columnDef: 'impressionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Impressions YOY',
            columnDef: 'impressionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Clicks
        {
            show: true,
            header: 'Clicks',
            columnDef: 'clicks',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Clicks',
            columnDef: 'clicksPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks MOM',
            columnDef: 'clicksMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Clicks',
            columnDef: 'clicksPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Clicks YOY',
            columnDef: 'clicksYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Click Through Rate
        {
            show: true,
            header: 'Click Through Rate',
            columnDef: 'clickThroughRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Click Through Rate',
            columnDef: 'clickThroughRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate MOM',
            columnDef: 'clickThroughRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Click Through Rate',
            columnDef: 'clickThroughRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Click Through Rate YOY',
            columnDef: 'clickThroughRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Total Visits
        {
            show: true,
            header: 'Total Visits',
            columnDef: 'totalVisits',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Total Visits',
            columnDef: 'totalVisitsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Visits MOM',
            columnDef: 'totalVisitsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Total Visits',
            columnDef: 'totalVisitsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total Visits YOY',
            columnDef: 'totalVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Unique Visitors
        {
            show: true,
            header: 'Unique Visits',
            columnDef: 'uniqueVisitors',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Unique Visits',
            columnDef: 'uniqueVisitorsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visits MOM',
            columnDef: 'uniqueVisitorsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Unique Visits',
            columnDef: 'uniqueVisitorsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Unique Visits YOY',
            columnDef: 'uniqueVisitsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Engagement Rate
        {
            show: true,
            header: 'Engagement Rate',
            columnDef: 'engagementRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Engagement Rate',
            columnDef: 'engagementRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Engagement Rate MOM',
            columnDef: 'engagementRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Engagement Rate',
            columnDef: 'engagementRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Engagement Rate YOY',
            columnDef: 'engagementRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Action Rate
        {
            show: true,
            header: 'Action Rate',
            columnDef: 'actionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Action Rate',
            columnDef: 'actionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Action Rate MOM',
            columnDef: 'actionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Action Rate',
            columnDef: 'actionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Action Rate YOY',
            columnDef: 'actionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Bounce Rate
        {
            show: true,
            header: 'Bounce Rate',
            columnDef: 'bounceRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Bounce Rate',
            columnDef: 'bounceRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate MOM',
            columnDef: 'bounceRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Bounce Rate',
            columnDef: 'bounceRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Bounce Rate YOY',
            columnDef: 'bounceRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Form Submissions
        {
            show: true,
            header: 'Form Submissions',
            columnDef: 'formSubmissions',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Form Submissions',
            columnDef: 'formSubmissionsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions MOM',
            columnDef: 'formSubmissionsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Form Submissions',
            columnDef: 'formSubmissionsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Form Submissions YOY',
            columnDef: 'formSubmissionsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Phone Calls
        {
            show: true,
            header: 'Phone Calls',
            columnDef: 'phoneCalls',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Phone Calls',
            columnDef: 'phoneCallsPreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Phone Calls MOM',
            columnDef: 'phoneCallsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Phone Calls',
            columnDef: 'phoneCallsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
          show: false,
          header: 'Phone Calls YOY',
          columnDef: 'phoneCallsYOY',
          isMom: false,
          isYoy: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        // Conversion Rate
        {
            show: true,
            header: 'Conversion Rate',
            columnDef: 'conversionRate',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Month Conversion Rate',
            columnDef: 'conversionRatePreviousMonth',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate MOM',
            columnDef: 'conversionRateMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Previous Year Conversion Rate',
            columnDef: 'conversionRatePreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Conversion Rate YOY',
            columnDef: 'conversionRateYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];

}
