import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Enums } from '../app/_shared/enums/enums';
import * as Models from './_shared/models/models-index';
import * as Services from './_shared/services/services-index';
import { ToasterConfig } from '@dkachko/angular2-toaster';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';
import { map, filter, tap, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from './_store/app-state.model';
import { selectLoggedInUser } from './_store/app.selectors';
import { AppActions } from './_store/action-types';
import { FilterActions } from './_shared/filter/store';
import { AppSelectors } from './_store/selector-types';
import { Title } from '@angular/platform-browser';
import { MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  toasterConfig: ToasterConfig = undefined;
  private routerSub: Subscription;
  private isUnsubscribe = true;
  private publicRoutes: string[] = ['/login', '/ssologin', '/ssologinV2', '/logout'];
  private baseUrl: string = environment.hostName;
  private user$ = this.store$.pipe(select(selectLoggedInUser));

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private store$: Store<AppState>,
    private appInsights: Services.AppInsightsService,
    private authService: Services.AuthenticationService,
    private configService: Services.ConfigurationService,
    private router: Router,
    private titleService: Title,
    private filterService: Services.FilterService,
    private cache: Services.LocalCacheService,
    private orgService: Services.OrgLookupService,
    private monacoLoaderService: MonacoEditorLoaderService
  ) {

    this.appInsights.setHostDomain(this.configService.hostInformation.hostName);
    this.toasterConfig = this.configService.toasterConfig;
  }

  verifyLoggedIn$ = this.user$.pipe(
    map(user => {
      if (user) {
        const exp = user.expireDate;
        const oneDay = (23 * 60 * 60 + 1) * 1000;
        const diff = exp.getTime() - (new Date()).getTime();
        if (diff > oneDay) {
          return true;
        }
      }
      return false;
    })
  );

  ngOnInit() {
    // this.store$.dispatch(AppActions.loadConfig());
    this.titleService.setTitle(environment.appName);
    this.favIcon.href = environment.favIconPath;


    const isSsoV2 = location.pathname.includes('ssoLoginV2') ? location.search.lastIndexOf('?authData', 0) === 0 : false;
    const isSso = location.search.lastIndexOf('?authData', 0) === 0;

    if (isSsoV2) {
      this.router.navigateByUrl('/ssoLoginV2' + location.search);
    } else if (isSso) {
      this.router.navigateByUrl('/ssoLogin' + location.search);
    }

    // Setup redirect route when navigating to /
    this.store$.select(AppSelectors.selectLoggedInUserAndConfig).pipe(
      tap(([userInfo, config]) => {
        if (!!userInfo && (!config || !config.dealerLookups || !config.dealerLookups.length)) {
          this.store$.dispatch(AppActions.loadConfig());
        }
      }),
      filter(([user, config]) => !!user && user.isAuthenticated && config.dealerLookups.length > 0)
    ).subscribe(([user, config]) => {
      this.orgService.setOrgLookups(config.orgLookups, user);

      if (user) {
        let redirectRoute = '';
        redirectRoute = '/leads/overview';
        const baseLayoutRoute = this.router.config.find(c => c.path === '');
        const defaultRoute = baseLayoutRoute.children?.find(c => c.path === '');

        if (defaultRoute) {
          defaultRoute.redirectTo = redirectRoute;
        }
      } else {
        this.router.navigateByUrl('/login');
      }
    });

    this.routerSub = this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationStart)) {
        // We don't want to call on the filterService when we're not authenticated because we don't have a hydrated authData object and the app pukes
        if (!this.publicRoutes.includes(evt.url.toLowerCase())) {
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.setupMonacoEditor();
  }

  setupMonacoEditor() {
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => isLoaded),
        take(1)
      )
      .subscribe(() => {
        // Define your theme here
        monaco.editor.defineTheme('appTheme', {
          base: 'vs-dark', // can also be vs or hc-black
          inherit: true, // can also be false to completely replace the builtin rules
          rules: [
            {
              token: 'comment',
              foreground: 'ffa500',
              fontStyle: 'italic underline'
            },
            { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
            { token: 'comment.css', foreground: '0000ff' } // will inherit fontStyle from `comment` above
          ],
          colors: {}
        });

        // Set your global JSON diagnostics options here
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: true,
          schemas: []
        });
      });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
  
}
