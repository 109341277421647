import {
    IDigAdScorecardConfig, IDigAdScorecardCategoryConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdScorecardConfig implements IDigAdScorecardConfig {
    public reportTitle = 'Digital Advertising Scorecard';
    public scorecards: IDigAdScorecardCategoryConfig[] = [
        {
            category: 'Awareness',
            billboards: [
                {
                    title: 'Clicks',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clicks',
                    metricMOMPropertyName: 'clicksMOM',
                    metricYOYPropertyName: 'clicksYOY',
                    metricPreviousMonthPropertyName: 'clicksPreviousMonth',
                    metricPreviousMTDPropertyName: 'clicksPreviousMTD',
                    metricPreviousYearPropertyName: 'clicksPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clicksMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardClicksHelp',
                    helpTextTitle: 'Clicks',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Impressions',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'impressions',
                    metricMOMPropertyName: 'impressionsMOM',
                    metricYOYPropertyName: 'impressionsYOY',
                    metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'impressionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'impressionsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardImpressionsHelp',
                    helpTextTitle: 'Impressions',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Click Through Rate',
                    subtitle: '',
                    iconClass: 'fa-mouse-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickThroughRate',
                    metricMOMPropertyName: 'clickThroughRateMOM',
                    metricYOYPropertyName: 'clickThroughRateYOY',
                    metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickThroughRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickThroughRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardClickThroughRateHelp',
                    helpTextTitle: 'Click Through Rate',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Cost Per Impression',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'calculatedBudgetCostPerImpression',
                    metricMOMPropertyName: 'calculatedBudgetCostPerImpressionMOM',
                    metricYOYPropertyName: 'calculatedBudgetCostPerImpressionYOY',
                    metricPreviousMonthPropertyName: 'calculatedBudgetCostPerImpressionPreviousMonth',
                    metricPreviousMTDPropertyName: 'calculatedBudgetCostPerImpressionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'calculatedBudgetCostPerImpressionPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'calculatedBudgetCostPerImpressionMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerImpressionHelp',
                    helpTextTitle: 'Cost Per Impression',
                    scoreCardCategory: 'Awareness'
                },
            ],
        },
        {
            category: 'Traffic',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'totalVisits',
                    metricMOMPropertyName: 'totalVisitsMOM',
                    metricYOYPropertyName: 'averageTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'totalVisitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'totalVisitsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Unique Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'uniqueVisits',
                    metricMOMPropertyName: 'uniqueVisitsMOM',
                    metricYOYPropertyName: 'uniqueVisitsYOY',
                    metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'uniqueVisitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Cost Per Click',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'calculatedBudgetCostPerClick',
                    metricMOMPropertyName: 'calculatedBudgetCostPerClickMOM',
                    metricYOYPropertyName: 'calculatedBudgetCostPerClickYOY',
                    metricPreviousMonthPropertyName: 'calculatedBudgetCostPerClickPreviousMonth',
                    metricPreviousMTDPropertyName: 'calculatedBudgetCostPerClickRatePreviousMTD',
                    metricPreviousYearPropertyName: 'calculatedBudgetCostPerClickPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'calculatedBudgetCostPerClickMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerClickHelp',
                    helpTextTitle: 'Cost Per Click',
                    scoreCardCategory: 'Traffic'
                },
            ]
        },
        {
            category: 'Engagement',
            billboards: [
                {
                    title: 'Time on Site',
                    subtitle: '',
                    iconClass: 'fa-clock-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'avgTimeOnSite',
                    metricMOMPropertyName: 'avgTimeOnSiteMOM',
                    metricYOYPropertyName: 'avgTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'avgTimeOnSitePreviousMonth',
                    metricPreviousMTDPropertyName: 'avgTimeOnSitePreviousMTD',
                    metricPreviousYearPropertyName: 'avgTimeOnSitePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'avgTimeOnSiteMOM',
                    metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    helpTextKey: 'DigAdScorecardTimeOnSiteHelp',
                    helpTextTitle: 'Time On Site',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Bounce Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'bounceRate',
                    metricMOMPropertyName: 'bounceRateMOM',
                    metricYOYPropertyName: 'bounceRateYOY',
                    metricPreviousMonthPropertyName: 'bounceRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'bounceRatePreviousMTD',
                    metricPreviousYearPropertyName: 'bounceRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'bounceRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardBounceRatesHelp',
                    helpTextTitle: 'Bounce Rate',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Engagement Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardEngagementRatesHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagement'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                {
                    title: 'Form Submissions',
                    subtitle: '',
                    iconClass: 'fa-file-text fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Conversion Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'conversionRate',
                    metricMOMPropertyName: 'conversionRateMOM',
                    metricYOYPropertyName: 'conversionRateYOY',
                    metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'conversionRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'conversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardConversionRateHelp',
                    helpTextTitle: 'Conversion Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Phone Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'phoneCalls',
                    metricMOMPropertyName: 'phoneCallsMOM',
                    metricYOYPropertyName: 'phoneCallsYOY',
                    metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'phoneCallsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                    helpTextTitle: 'Phone Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Action Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Cost Per Conversion',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'calculatedBudgetCostPerAcquisition',
                    metricMOMPropertyName: 'calculatedBudgetCostPerAcquisitionMOM',
                    metricYOYPropertyName: 'calculatedBudgetCostPerAcquisitionYOY',
                    metricPreviousMonthPropertyName: 'calculatedBudgetCostPerAcquisitionPreviousMonth',
                    metricPreviousMTDPropertyName: 'calculatedBudgetCostPerAcquisitionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'calculatedBudgetCostPerAcquisitionPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'calculatedBudgetCostPerAcquisitionMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerAcquisitionHelp',
                    helpTextTitle: 'Cost Per Conversion',
                    scoreCardCategory: 'Actions'
                },
            ]
        }
    ];
}
