import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

    public routes: IRouteConfig[] = [
      {
        id: 'website',
        category: 'website',
        default: true,
        defaultRoute: '/website/overview',
        displayName: 'Website',
        displayInNav: true,
        iconClass: 'fa fa-globe',
        roles: undefined,
        children: [
          {
              parent: '/website',
              route: 'overview',
              displayName: 'Overview',
              pdfEnabled: false,
              breadcrumbHelpEnabled: true,
              iconClass: 'fa fa-users',
              roles: undefined
          },
          {
            parent: '/website',
            route: 'scorecard',
            displayName: 'Scorecard',
            iconClass: 'fa fa-list',
            roles: ['System Administrator', 'Corporate', 'Region Manager', 'District Manager', 'Dealer']
          },
          {
            parent: '/website',
            route: 'summary',
            displayName: 'Summary',
            iconClass: 'fa fa-list',
            breadcrumbHelpEnabled: true,
            roles: undefined
          },
          {
            parent: '/website',
            route: 'referrer',
            displayName: 'Referrer',
            iconClass: 'fa fa-share',
            roles: undefined
          },
          {
            parent: '/website',
            route: 'provider',
            displayName: 'Provider',
            iconClass: 'fa fa-cogs',
            roles: ['System Administrator', 'Corporate']
          },
          {
            parent: '/website',
            route: 'vehicle',
            displayName: 'Vehicle',
            iconClass: 'fa fa-car',
            roles: undefined
          },
        ]
      },
      {
        id: 'digAd',
        category: 'digAd',
        default: true,
        defaultRoute: '/digAd/overview',
        displayName: 'Digital Advertising',
        displayInNav: true,
        iconClass: 'fa fa-desktop',
        roles: undefined,
        children: [
          {
            parent: '/digAd',
            route: 'overview',
            displayName: 'Overview',
            pdfEnabled: false,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
          },
          {
            parent: '/digAd',
            route: 'scorecard',
            displayName: 'Scorecard',
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-list',
            roles: undefined
          },
          {
            parent: '/digAd',
            route: 'summary',
            displayName: 'Summary',
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-list',
            roles: undefined
          },
          {
            parent: '/digAd',
            route: 'provider',
            displayName: 'Provider',
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-cogs',
            roles:  ['System Administrator', 'Corporate', 'Region Manager', 'DistrictManager']
          }
        ]
      },
      {
        id: 'provider-tools',
        category: 'provider-tools',
        default: true,
        defaultRoute: '/provider-tools/chat-overview',
        displayName: 'Provider Tools',
        displayInNav: true,
        iconClass: 'fa fa-building',
        roles: undefined,
        children: [
            {
                parent: '/provider-tools',
                route: 'chat-overview',
                displayName: 'Chat Overview',
                pdfEnabled: false,
                breadcrumbHelpEnabled: true,
                iconClass: 'fa fa-users',
                roles: undefined
            }
        ]
      },
      {
        id: 'management',
        category: 'management',
        defaultRoute: '/management/users',
        displayName: 'Management',
        displayInNav: true,
        iconClass: 'fa fa-cog',
        roles: ['System Administrator'],
        children: [
          {
            parent: '/management',
            route: 'content',
            displayName: 'Content',
            iconClass: 'fa fa-code',
            roles: ['System Administrator']
          },
          {
            parent: '/management',
            route: 'users',
            displayName: 'Users',
            iconClass: 'fa fa-users',
            roles: ['System Administrator']
          }
        ]
      }
    ];
}
