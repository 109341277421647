import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SdFilterPanelValueService } from '../services/sd-filter-panel-value.service';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { SdFilterPanelRef } from '../types/sd-filter-panel-ref';
import { IFilterTreeNode, FilterFlatNode } from '../types/types';
import * as FilterPanelTypes from '../types/types';
import { NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfigurationService, LocaleService, SharedTranslationService } from '../../services/services-index';

const _transformer = (node: IFilterTreeNode, level: number) => {
  return {
    expandable: !!node.children && node.children.length > 0,
    display: node.display,
    value: node.value,
    unselectable: node.unselectable,
    orgFilterValue: node.orgFilterValue,
    treeFilterNode: node,
    level: level,
    formatDisplay: node.formatDisplay
  };
};

@Component({
  selector: 'filter-panel-presenter',
  templateUrl: './sd-filter-panel-collection.component.html',
  styleUrls: ['./sd-filter-panel-collection.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SdFilterPanelCollectionComponent implements OnInit, OnDestroy, AfterViewInit {
  private _subscription = new Subscription();

  panelData: IFilterTreeNode[] = [];
  filteredPanelData: IFilterTreeNode[] = [];

  treeDataSource = new MatTreeNestedDataSource<IFilterTreeNode>();

  treeControl = new FlatTreeControl<FilterFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(
    _transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  selected: any[] = [];
  // indeterminate: boolean = false;
  checkBoxHeaderLabel: string;
  checkBoxHeaderLabelSuffix: string;
  totalNodeCount = 0;

  searchTerm = '';
  searchHasFocus = false;
  isSearching = false;
  searchResults: any[] = [];
  showProgress = false;
  searchPlaceholder = 'Search';
  rippleColor = 'rgba(0, 0, 0, .10)';

  checkListSelection = new SelectionModel<FilterFlatNode>(!this.filterPanelRef.config.singleSelectionMode);

  @ViewChild('panelDataSelectList', { static: true }) panelDataSelectList: MatSelectionList;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  hasChild = (_: number, node: FilterFlatNode) => node.expandable;
  trackBy = (index: number, item: FilterFlatNode) => `${item.orgFilterValue.levelTypeId}-${item.orgFilterValue.value}`;

  getNodeDisplayValue = (node: FilterFlatNode) => {
    if (node.formatDisplay) {
      return node.formatDisplay(node.treeFilterNode);
    }

    return node.display;
  }

  constructor(
    private filterPanelRef: SdFilterPanelRef,
    private valueService: SdFilterPanelValueService,
    private configService: ConfigurationService,
    private localeService: LocaleService,
    private translationService: SharedTranslationService) {

  }

  ngOnInit() {
    this._subscription.add(
      this.filterPanelRef.config.inputData
        .subscribe(inputData => {
          this.panelData.push(...<FilterPanelTypes.IFilterTreeNode[]>inputData.inputData);
          this.valueService.updateTreeDataSource(this.panelData);
        })
    );

    this._subscription.add(
      this.valueService.treeDataSource$
        .subscribe((nodes) => {
          this.dataSource.data = nodes;

          const currentFilterValues = this.filterPanelRef.config.currentFilterValues;
          if (currentFilterValues && currentFilterValues.inputData) {
            this.valueService.setSelectedItems(this.filterPanelRef.config.currentFilterValues.inputData);
          }
        })
    );

    this._subscription.add(
      this.localeService.locale$.subscribe(loc => this.searchPlaceholder = this.translationService.getLabelTranslation(this.searchPlaceholder, loc))
    );

    this._subscription.add(
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          map((event: any) => {
            this.showProgress = true;
            return event;
          }),
          filter(event => {
            const isValidKey = this.isValidKey(event);
            if (!isValidKey) {
              this.showProgress = false;
              return false;
            }
            return true;
          }),
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe((event: any) => {
          this.searchTerm = event.target.value;
          this.isSearching = !!this.searchTerm;

          this.filterSelectList();
          this.showProgress = false;
        })
    );
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this._subscription.add(
      this.valueService.selectedItems$.subscribe(items => {
        this.setSelectionState(<IFilterTreeNode[]>items);
      }));
  }

  compareOptions = (val1: FilterPanelTypes.FilterFlatNode, val2: FilterPanelTypes.FilterFlatNode) => val1.value === val2.value;

  isTreeNodeSelected(node: FilterFlatNode): boolean {
    if (this.filterPanelRef.config.singleSelectionMode) {
      return this.checkListSelection.selected.findIndex(s => this.compareOptions(s, node)) >= 0;
    } else {
      const childNodes = this.treeControl.getDescendants(node);
      const allIsSelected = childNodes.every(c => this.checkListSelection.isSelected(c));

      return allIsSelected;
    }
  }

  childNodesPartiallySelected(node: FilterFlatNode): boolean {
    if (this.isTreeNodeSelected(node)) {
      return false;
    }

    const childNodes = this.treeControl.getDescendants(node);
    const result = childNodes.some(c => this.checkListSelection.isSelected(c));

    return result && !this.isTreeNodeSelected(node);
  }

  clearAllSelected(): void {
    this.deselectAll();
    this.treeControl.collapseAll();
  }

  clearSearchTerm($event): void {
    this.searchTerm = undefined;
    this.isSearching = false;
    this.filterSelectList();
  }

  isLeafChecked(node: FilterFlatNode): boolean {
    return this.checkListSelection.isSelected(node);
  }

  isChecked(node: FilterFlatNode): boolean {
    return this.isTreeNodeSelected(node);
  }

  isIndeterminate(): boolean {
    const checkSelected = this.checkListSelection.selected.filter(cs => !cs.expandable);
    return checkSelected.length > 0 && checkSelected.length < this.totalNodeCount;
  }

  leafNodeSelectionToggle(node: FilterFlatNode): void {
    this.checkListSelection.toggle(node);
    this.checkListSelection.isSelected(node) ? this.addToValueService(node.treeFilterNode) : this.valueService.removeSelectedItem(node.treeFilterNode);
  }

  removeSelectedNode(node: FilterFlatNode): void {
    this.checkListSelection.deselect(node);
    this.valueService.removeSelectedItem(node.treeFilterNode);
  }

  /* Handles Parent Node Clicks */
  nodeSelectionToggle(node: FilterFlatNode) {

    if (this.filterPanelRef.config.singleSelectionMode) {
      this.deselectAll();
      this.checkListSelection.toggle(node);
    }

    if (this.checkListSelection.isMultipleSelection()) {
      const childNodes = this.treeControl.getDescendants(node);
      const allChildNodesSelected: boolean = this.isTreeNodeSelected(node);
      const someChildNodesSelected: boolean = this.childNodesPartiallySelected(node);

      // TODO: Rewrite the shit out of this
      if (!this.isTreeNodeSelected(node) && !allChildNodesSelected) {
        childNodes.forEach((n) => {
          if (!this.checkListSelection.isSelected(n)) {
            this.checkListSelection.select(n);
            this.valueService.addSelectedItem(n.treeFilterNode);
          }
        });
        this.treeControl.expandDescendants(node);
      } else if (!this.isTreeNodeSelected(node) && someChildNodesSelected) {
        childNodes.forEach((n) => {
          if (!this.checkListSelection.isSelected(n)) {
            this.checkListSelection.select(n);
            this.valueService.addSelectedItem(n.treeFilterNode);
          }
        });
        this.treeControl.expandDescendants(node);
      } else {
        this.checkListSelection.deselect(...childNodes);
        this.valueService.deselectItems(childNodes.map(cn => cn.treeFilterNode));
        this.treeControl.collapseDescendants(node);
      }
    } else {
      if (this.isTreeNodeSelected(node)) {
        this.addToValueService(node.treeFilterNode);
      }
    }
  }

  private handle

  private clearSelectList() {
    this.checkListSelection.clear();
  }

  private deselectAll() {
    this.checkListSelection.clear();
  }

  private addToValueService(node: IFilterTreeNode) {
    if (this.filterPanelRef.config.singleSelectionMode) {
      this.valueService.clear();
    }
    this.valueService.addSelectedItem(node);
  }

  private getNodeChildNodeCount(node: IFilterTreeNode): number {
    let count = 0;

    if (node.children && node.children.length) {
      count += node.children.length;

      node.children.forEach((childNode) => {
        count += this.getNodeChildNodeCount(childNode);
      });
    }

    return count;
  }

  private isValidKey(event: KeyboardEvent): boolean {
    return event.keyCode !== 13
      && event.keyCode !== 27
      && event.code !== 'Enter'
      && event.code !== 'Escape'
      && event.key !== 'Enter'
      && event.key !== 'Escape';
  }

  private manageCheckListSelection() {
    if (this.isSearching) {
      this.checkListSelection.clear();
      this.selected.forEach((node) => {
        if (this.filteredPanelData.indexOf(node) >= 0) {
          this.checkListSelection.select(node);
        }
      });
    } else {
      if (this.checkListSelection.selected.length !== this.selected.length) {
        this.selected.forEach((node) => {
          if (!this.checkListSelection.isSelected(node)) {
            this.checkListSelection.select(node);
          }
        });
      }
    }
  }

  private setTreeNodeCount() {
    this.totalNodeCount = 0;
    if (!this.isSearching) {
      this.filteredPanelData.forEach((rootNode) => {
        this.totalNodeCount += this.getNodeChildNodeCount(rootNode);
      });
    } else {
      this.totalNodeCount = this.filteredPanelData.length;
    }

  }

  private setPanelHeaderCheckboxLabel(): void {
    this.checkBoxHeaderLabelSuffix = this.filteredPanelData.length > 1 ? 's' : '';
    const title: string = this.searchTerm && this.searchTerm !== ''
      ? `matching: ${this.searchTerm}`
      : `${this.filterPanelRef.config.panelTitle}${this.checkBoxHeaderLabelSuffix}`;

    this.checkBoxHeaderLabel = `${this.filteredPanelData.length} ${title}`;
  }

  private filterSelectList() {
    if (this.searchTerm && this.searchTerm !== '') {
      const filteredNodes = this.nodeSearch(this.panelData);

      this.valueService.updateTreeDataSource(filteredNodes);
    } else {
      this.valueService.updateTreeDataSource(this.panelData);
    }
  }

  private nodeSearch(nodes: IFilterTreeNode[]) {
    const results: IFilterTreeNode[] = [];

    nodes.forEach(node => {
      if ((node.display.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1)
        || node.value.toString().toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
        results.push(node);
      }

      if (node.children && node.children.length > 0) {
        results.push(...this.nodeSearch(node.children));
      }
    });

    return results;
  }

  private setSelectionState(currentFilterValues: IFilterTreeNode[]) {
    currentFilterValues.forEach((filterValue) => {
      // look up filter node representation
      const orgFilterValue = filterValue.orgFilterValue;
      const node = this.treeControl.dataNodes.find(n => n.value === filterValue.value);
      if (node && !this.checkListSelection.isSelected(node)) {
        this.checkListSelection.select(node);
      }
    });
  }
}
