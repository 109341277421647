export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Porsche-NA',
  hostName: 'analytics.porschedealerdigitalprogram.com',
  environmentName: 'production',
  appName: 'Porsche Analytics',
  applicationInsights: {
    instrumentationKey: '4624c3dc-875e-4390-a68a-98a4c1a923c1'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#34383c', '#c51230', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://porschena-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://porschena-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: '',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/porsche-na/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: []
};
