import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ContentListResponseModel, ContentModel, ListRequestModel, ListResponseModel } from '../../models/models-index';
import * as Models from '../../../_shared/models/models-index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() { }

  ngOnDestroy(): void { }

  private api = `${environment.baseApiUri}/content/`;
  // private api = `http://localhost:25406/api/content/`;

  getContent(name: string, locale?: string) {
    const localeSuffix = (!locale || locale === 'en') ? '' : '_' + locale;
    return this.http.get<ContentModel>(`${this.api}?name=${name + localeSuffix}`);
  }

  getList(requestModel: ListRequestModel) {
    return this.http.post<ContentListResponseModel>(
      `${this.api}/list`,
      requestModel,
      { headers: this.headers });
  }

  processContent(content: ContentModel, file: File, thumbnail: File = null): Observable<any> {
    let request: Observable<any>;

    switch (content.type) {
      case 'File':
        let fileContentModel = <Models.FileContentModel>content;
        fileContentModel.file = file;
        fileContentModel.thumbnail = thumbnail;
        request = this.upsertFileContent(fileContentModel);
        break;
      case 'Section':
        let sectionContentModel = <Models.SectionContentModel>content;
        request = !sectionContentModel.id
          ? this.createContent(sectionContentModel)
          : this.updateContent(sectionContentModel);
        break;
      default: // Html Content
        let htmlContentModel = <Models.HtmlContentModel>content;
        htmlContentModel.html = <string>htmlContentModel.value;
        request = !htmlContentModel.id
          ? this.createContent(htmlContentModel)
          : this.updateContent(htmlContentModel);
        break;
    }

    return request;
  }

  createContent(content: ContentModel) {
    return this.http.post<ContentModel>(
      this.api,
      content,
      { headers: this.headers }
    );
  }

  updateContent(content: ContentModel) {
    return this.http.put<ContentModel>(
      this.api + content.id,
      content,
      { headers: this.headers }
    );
  }

  upsertFileContent(content: Models.FileContentModel) {
    let formData = new FormData();
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');

    if (content.file) {
      // convert File to Blob and append it to FormData with the 'main' prefix
      let mainFile = new Blob([content.file], { type: content.file.type });
      formData.append('main-' + content.file.name, mainFile, content.file.name);
    }

    if (content.thumbnail) {
      // convert File to Blob and append it to FormData with the 'thumbnail' prefix
      let thumbnailFile = new Blob([content.thumbnail], { type: content.thumbnail.type });
      formData.append('thumbnail-' + content.thumbnail.name, thumbnailFile, content.thumbnail.name);
    }

    if(content.id)
    {
      // update
      let queryParams = `?userId=${content.createdBy}&id=${content.id}&active=${content.isActive}&sectionId=${content.sectionId}&order=${content.order}`;

      return this.http.put(`${this.api}/file${queryParams}`, formData, { headers });
    } else {
      // insert
      let queryParams = `?name=${encodeURIComponent(content.name)}&userId=${content.createdBy}&active=${content.isActive}&sectionId=${content.sectionId}&order=${content.order}`;

      return this.http.post(`${this.api}/file${queryParams}`, formData, { headers });
    }
  }

  deleteContent(id: number) {
    return this.http.delete(this.api + id);
  }
}
