import {
    ILeadsOverviewBillboardConfig,
    ILeadsOverviewConfig,
    IDefaultTableColumnConfig,
    ILeadsOverviewDealerKpiConfig,
    ILeadsOverviewKpiConfig,
    INameValuePair,
    ILeadsOverviewSourceTypeCardConfig,
    IPreviousDateRangeDisplay} from '../../../../_shared/models/models-index';

export class LeadsOverviewConfig implements ILeadsOverviewConfig {
    public reportTitle: string;
    public metricDisplayModes: INameValuePair[] = [];
    public useDefaultComparisonColumns: boolean = false;
    public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = null;
    public billboards: ILeadsOverviewBillboardConfig[] = [];
    public dealerbillboards: ILeadsOverviewBillboardConfig[] = [];
    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [];
    public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
    public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = null;
    public kpiCardConfig: ILeadsOverviewKpiConfig = null;
    public lookbackMonths?: number;
    public previousDateColumnDisplayOverrides?: IPreviousDateRangeDisplay = null;
    // what is this public dealerLevelPropertyOverrides?: IPropertyOverride[];
}
