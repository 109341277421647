import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, ViewChild, Type, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as modals from '../../../../_shared/modals/modals-index';

@Component({
  selector: 'overview-full-width-panel',
  templateUrl: './overview-full-width-panel.component.html',
  styleUrls: ['./overview-full-width-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverviewFullWidthPanelComponent implements Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  @Input() dataSets: any[];
  @Input() rowPanelId: string;
  @Output() openHelpText = new EventEmitter();
  @Output() changeMetricSelect = new EventEmitter();

  panelConfiguration: Models.PanelConfiguration;

  constructor(private resolverService: PanelElementResolverService, private renderer2: Renderer2, private router: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.panelConfiguration = this.panelConfig.configurations[0];
    this.generateElements(this.panelConfiguration, this.dataSets);
  }

  metricSelectChanged(): void {
    this.changeMetricSelect.emit();
  }

  // openHelpTextClicked(): void {
  //   this.openHelpText.emit();
  // }

  // help
  openHelpTextClicked() {
    this.dialog.open(modals.SharedHelpTextModalComponent, {
      width: '50vw',
      data: {
        title: this.panelConfiguration.helpTextTitle,
        name: this.panelConfiguration.helpTextKey
      }
    });
  }

  handleTitleClick() {
    let filterOverrides = [];
    this.panelConfiguration.titleLinkUrlFilters.forEach(filter => {
      filterOverrides.push({type: filter.type, values: filter.values})
    })

    this.router.navigate([this.panelConfiguration.titleLinkUrl], { state: { filterOverrides: filterOverrides } })
  }

  generateElements(panelConfig, dataSets) {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();

    panelConfig.elements.forEach(element => {
      const dataSet = dataSets.find(ds => ds.name === element.settings.dataSet);

      const componentFactory = this.resolverService.resolveElementComponent(element.type)
      const componentRef = viewContainerRef.createComponent<Models.ElementComponent>(componentFactory);
      componentRef.instance.dataSet = dataSet;
      componentRef.instance.settings = element.settings;
      this.renderer2.addClass(componentRef.location.nativeElement, 'panel-element');
    })
  }
}
