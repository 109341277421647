import { createReducer, on } from '@ngrx/store';
import { FilterActions } from './action-types';
import * as FilterModels from '../filter.model';
import { FilterState, initialFilterState } from './filter-state.model';
import { Filter } from '../filter.model';

const updateReportFilters = (state: FilterState, reportName: string, filters: Filter[], lockedFilters?: Filter[]) => {
  const newState = {...state};

  // associate new set of locked filters
  if (!!lockedFilters) {
    newState.lockedFilters = [...lockedFilters];
  }

  // associate new set of report filters
  const existing = newState.filters.find(filter => filter.reportName === reportName);

  if (existing) {
    const newReportFilters = [...newState.filters];

    newReportFilters.splice(
      newReportFilters.findIndex(rf => rf.reportName === existing.reportName),
      1,
      { reportName, filters }
    );
    newState.filters = newReportFilters;
  } else {
    newState.filters = [...newState.filters, { reportName, filters }];
  }

  return newState;
};

const _filterReducer = createReducer(
  initialFilterState,
  on(FilterActions.initializeFiltersSuccess, (state, action) => {
    let newState = {...state};

    // update report filters
    newState = updateReportFilters(newState, action.reportName, action.filters);

    // check to see if we got passed locked filters that need to be updated or set
    action.lockedFilters.forEach(locked => {
      const otherLocked = newState.lockedFilters.filter(lf => lf.type !== locked.type);
      newState.lockedFilters = [...otherLocked, locked];
    });
    return newState;
  }),
  on(FilterActions.updatePrintingFilters, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters);

    return newState;
  }),
  on(FilterActions.updateCurrentReportFilterSelectedSuccess, (state, action) => {
    return action.filterState;
  }),
  on(FilterActions.toggleFilterLockSuccess, (state, action) => {
    return action.filterState;
  }),
  on(FilterActions.removeFilterSuccess, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters, action.lockedFilters);

    return newState;
  }),
  on(FilterActions.resetFilters, (state, _) => {
    const newState = {...state, lockedFilters: [], filters: [], explicitUnlockedFilters: [] };
    return newState;
  }),
  on(FilterActions.updateReportOrgFilterSelectedSuccess, (state, action) => {
    const newState = updateReportFilters(state, action.reportName, action.filters);

    return newState;
  }),
  on(FilterActions.updateOptions, (state, action) => {
    return {...state, options: action.options};
  })
);

export function filterReducer(state, action) {
  return _filterReducer(state, action);
}
