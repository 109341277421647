import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import chroma from 'chroma-js';
import { ChartService } from '../../chart/chart.service';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'stacked-column-chart',
  templateUrl: './stacked-column-chart.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StackedColumnChartComponent implements ElementModels.ElementComponent, OnInit, OnDestroy {

  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetric: string;

  componentActive: boolean = true;
  currentChart: any;
  primaryMetricName: string;
  primaryMetricDisplayValue: string;

  constructor(private chartService: ChartService) { }

  ngOnInit() {
    const dateIndex = this.dataSet.columns.findIndex(col => col.name === 'Date');
    const dimIndex = this.dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === this.selectedMetric);
    const metricColumn = this.dataSet.columns[metricIndex];

    const categories = [];
    const seriesNames = [];

    this.dataSet.rows.forEach(row => {
        if (!row[dimIndex].value || row[dimIndex].label === ''
            || this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value || f === row[dimIndex].label))
            return;

        if (!row[metricIndex].value)
            return;

        const date = row[dateIndex].value;
        const dim = row[dimIndex].value;
        const metric = row[metricIndex].value;

        if (!categories.includes(date)) {
            categories.push(date);
        }

        // sort categories by date
        categories.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        if (!seriesNames.includes(dim)) {
            seriesNames.push(dim);
        }
    });

    const seriesData = seriesNames.map(seriesName => {
      const data = categories.map(category => {
        const row = this.dataSet.rows.find(
          row => row[dateIndex].value === category && row[dimIndex].value === seriesName
        );
        return row ? {
          name: seriesName,
          y: row[metricIndex].value || 0, 
          label: row[metricIndex].label || row[metricIndex].value || 0 
        }: 0;
      });
      return { name: seriesName, data };
    });

    // seriesNames.forEach(seriesName => {
    //     let seriesValues = [];

    //     categories.forEach(category => {
    //         const row = this.dataSet.rows.find(row => row[dateIndex].value === category && row[dimIndex].value === seriesName);
    //         if (row && row[metricIndex].value) {
    //             seriesValues.push(row[metricIndex].value);
    //         } else {
    //             seriesValues.push(0);
    //         }
    //     });

    //     seriesData.push({ name: seriesName, data: seriesValues});
    // });

    // convert categories to date objects
    const dateCategories = categories.map(category => new Date(category));

    this.currentChart = this.generateChart(seriesData, dateCategories);
    
    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 200);
      })
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(seriesData: any[], categories: any[]) {
    const colors = this.generateChartColors(this.settings.chartPrimaryColor, this.settings.chartSecondaryColor, seriesData.length);

    const chartSeries: SeriesOptionsType[] = [{
      type: 'column',
      data: seriesData
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: colors,
      chart: {
        type: 'column',
        //showAxes: false,
        height: 400
      },
      // AXES.....
      xAxis: {
        categories: categories,
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%m/%d/%Y'
          },
          labels: {
            formatter: function() {
              const date = new Date(this.value);
              return date.toLocaleDateString();
            }
          }
        // title: { text: null },
        // lineWidth: 0,
        // minorGridLineWidth: 0,
        // lineColor: 'transparent',
        // minorTickLength: 0,
        // tickLength: 0,
      },
      yAxis: {
        //tickInterval: 20,
        // labels: {
        //   format: '{value}%'
        // },
        title: {
            text: ''
        },
        stackLabels: {
            enabled: false
        }
      },
      tooltip: {
        formatter: function() {
            const point = <any>this.point;
            return `<b>${new Date(this.x).toLocaleDateString()}</b><br/>${this.series.name}: ${point.label}<br> % of Total: <b>${ (this.point.percentage).toFixed(2) }%</b>`;
        },
      },
      plotOptions: {
          series: {
              stacking: 'normal',
              dataLabels: {
                    enabled: false
              }
          }
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom'
      },
      series: seriesData
    });

    return chart;
  }

  generateChartColors(darkColor: string, lightColor: string, length: number): string[] {
    const colors = chroma.scale([darkColor, lightColor])
      .mode('lch')
      .colors(length);

    return colors;
  }
}
