import {createFeatureSelector, createSelector} from '@ngrx/store';
import { States } from './app.reducer';
import { Enums } from '../_shared/enums/enums';
import * as fromRouter from '@ngrx/router-store';
import { AppState, ConfigState } from './app-state.model';
import { IAuthenticationInfo } from '../_shared/models/models-index';

/* Misc States */
export interface RouterState {
  router: fromRouter.RouterReducerState<any>;
}

/* Feature Selectors */

export const selectAuthState =
    createFeatureSelector<States.AuthState>('auth');

export const selectConfigState =
  createFeatureSelector<States.ConfigState>('config');

export const selectLocaleState =
  createFeatureSelector<States.LocaleState>('locale');

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

  /* Router Selectors */
export const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const selectRouteId = selectRouteParam('id');

export const selectCurrentRouteData = createSelector(
  selectRouteData,
  selectUrl,
  (data, url) => {
    return { reportName: data.reportName, url: url, hideFilters: data.hideFilters, showDateFilter: data.showDateFilter, hideBreadcrumbs: data.hideBreadcrumbs, showMetricSelector: data.showMetricSelector };
  }
);

export const selectReportNameAndConfig = createSelector(
  selectRouteData,
  selectConfigState,
  (data, config) => ({ reportName: data?.reportName, config: config })
);


/* Auth Selectors */
export const selectLoggedInUser = createSelector(
  selectAuthState,
  auth => auth.auth
);

export const isLoggedIn = createSelector(
  selectLoggedInUser,
  auth => !!auth
);

export const isLoggedOut = createSelector(
  isLoggedIn,
  loggedIn => !loggedIn
);

/* Config Selectors */
export const selectDealerHierarchys = createSelector(
  selectConfigState,
  config => config.dealerHierarchys
);
export const selectDealerGroupLookups = createSelector(
  selectConfigState,
  config => config.dealerGroupLookups
);
export const selectDealerLookups = createSelector(
  selectConfigState,
  config => config.dealerLookups
);
export const selectOrgLookups = createSelector(
  selectConfigState,
  config => config.orgLookups
);
export const selectLmaLookups = createSelector(
  selectConfigState,
  config => config.lmaLookups
);
export const selectVirtual20Lookups = createSelector(
  selectConfigState,
  config => config.virtual20Lookups
);

export const selectTimeZone = createSelector(
  selectConfigState,
  config => config.clientTimeZone
);

const unique = (value, index, self) => {
  return self.map(v => v.orgCode).indexOf(value.orgCode) === index;
};

export const getOrgCode1s = createSelector(
  selectOrgLookups,
  orgLookups => orgLookups
                .map(ol => ({ orgCode: ol.orgCode1.toUpperCase(), orgCodeName: ol.orgCode1Name }))
                .filter(unique)
);

export const getOrgCode2s = createSelector(
  selectOrgLookups,
  orgLookups => orgLookups
                .map(ol => ({ orgCode2: ol.orgCode2.toUpperCase(), orgCode2Name: ol.orgCode2Name }))
                .filter(unique)
);

export const getOrgCode3s = createSelector(
  selectOrgLookups,
  orgLookups => orgLookups
                .map(ol => ({ orgCode3: ol.orgCode3.toUpperCase(), orgCode3Name: ol.orgCode3Name }))
                .filter(unique)
);

export const getOrgCode4s = createSelector(
  selectOrgLookups,
  orgLookups => orgLookups
    .map(ol => ({ orgCode4: ol.orgCode4.toUpperCase(), orgCode4Name: ol.orgCode4Name }))
    .filter(unique)
);

export const getOrgCode5s = createSelector(
  selectOrgLookups,
  orgLookups => orgLookups
    .map(ol => ({ orgCode5: ol.orgCode5.toUpperCase(), orgCode5Name: ol.orgCode5Name }))
    .filter(unique)
);

export const selectChannels = createSelector(
  selectConfigState,
  config => config.channels
);

export const selectBrands = createSelector(
  selectConfigState,
  config => config.brands
);

export const selectCampaignTypes = createSelector(
  selectConfigState,
  config => config.campaignTypes
);

export const selectAudienceSegments = createSelector(
  selectConfigState,
  config => config.audienceSegments
);

export const selectJourneySegments = createSelector(
  selectConfigState,
  config => config.journeySegments
);

export const selectRoles = createSelector(
  selectConfigState,
  config => config.roles
);

export const selectPages = createSelector(
  selectConfigState,
  config => config.pages
);

export const selectDealerSizes = createSelector(
  selectConfigState,
  config => config.dealerSizes
);

export const selectChatProviders = createSelector(
  selectConfigState,
  config => config.chatProviders
);

export const selectTradeInProviders = createSelector(
  selectConfigState,
  config => config.tradeInProviders
);

export const selectServiceSchedulerProviders = createSelector(
  selectConfigState,
  config => config.serviceSchedulerProviders
)

export const selectDeviceTypes = createSelector(
  selectConfigState,
  config => config.deviceTypes
);

export const selectDigAdProviders = createSelector(
  selectConfigState,
  config => config.digAdProviders
);

export const selectProfitCenters = createSelector(
  selectConfigState,
  config => config.profitCenters
);

export const selectDigAdPrograms = createSelector(
  selectConfigState,
  config => config.digAdPrograms
)

export const selectProviders = createSelector(
  selectConfigState,
  config => config.providers
);

export const selectLeadTypes = createSelector(
  selectConfigState,
  config => config.leadTypes
);

export const selectVehicleLookups = createSelector(
  selectConfigState,
  config => config.vehicleLookups
);

export const selectVehicleMakes = createSelector(
  selectConfigState,
  config => config.vehicleMakes
);

export const selectVehicleClasses = createSelector(
  selectConfigState,
  config => config.vehicleClasses
);

export const selectVehicleModels = createSelector(
  selectConfigState,
  config => config.vehicleModels
);

export const selectSaleTypes = createSelector(
  selectConfigState,
  config => config.saleTypes
);

export const selectReputationPlatforms = createSelector(
  selectConfigState,
  config => config.reputationPlatforms
);

export const selectReputationProviders = createSelector(
  selectConfigState,
  config => config.reputationProviders
);

export const selectSocialMediaPlatforms = createSelector(
  selectConfigState,
  config => config.socialMediaPlatforms
);

export const selectSocialMediaProviders = createSelector(
  selectConfigState,
  config => config.socialMediaProviders
);

export const selectSourceTypes = createSelector(
  selectConfigState,
  config => config.sourceTypes
);

export const selectTraits = createSelector(
  selectConfigState,
  config => config.traitFilters
)

export const selectLeadGates = createSelector(
  selectConfigState,
  config => config.leadGates
)

export const selectVehicleTypes = createSelector(
  selectConfigState,
  config => config.vehicleTypes
)

export const selectDealTypes = createSelector(
  selectConfigState,
  config => config.dealTypes
)

export const selectWebsiteProviders = createSelector(
  selectConfigState,
  config => config.websiteProviders
);

export const selectLocale = createSelector(
  selectLocaleState,
  localeState =>  localeState.locale
);

export const selectLoggedInUserAndConfig = createSelector(
  selectLoggedInUser,
  selectConfigState,
  (user, config): [IAuthenticationInfo, ConfigState] => [user, config]
);

