<div *ngIf="layoutHelper.sideNavToggleAction$ | async as toggleStatus" id="app-breadcrumb">
  <ng-container *ngIf="locale$ | async as locale">
    <div class="breadcrumb">
      <div id="title-section">
        <ng-container *ngIf="pageTitle$ | async as pageTitle">
          <h2 style="width:auto;">{{pageTitle | translatelayout: locale}}</h2>
        </ng-container>
        <sd-filter-breadcrumbs hidden [filterModel]="filterModel$"></sd-filter-breadcrumbs>
      </div>
      <div id="date-filter-section">
        <!-- Breadcrumb options -->
        <ng-container *ngIf="showBreadcrumbOptions$ | async">
          <sd-metric-selector *ngIf="showMetricSelector"></sd-metric-selector>
          <div id="date-filter-and-icons">
            <layout-sddaterangepicker *ngIf="showDateFilter"></layout-sddaterangepicker>
            <ng-container *ngIf="showBreadcrumbMenu">
              <div id="help-button">
                <button mat-button [matMenuTriggerFor]="printingOptions" class="card-options-button">
                  <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i>
                </button>
                <mat-menu #printingOptions="matMenu" class="pt-10">
                  <button mat-menu-item *ngIf="helpAvailable"
                    (click)="openHelp('StickyFiltersHelp', 'General', locale)">{{'Help' | translate: locale}}</button>
                  <button mat-menu-item *ngIf="pdfAvailable" (click)="onClickPDF()">PDF</button>
                </mat-menu>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>