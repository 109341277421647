import * as Models from '../_shared/models/models-index';
import { RouterReducerState } from '@ngrx/router-store';
import { Config } from 'protractor';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterState, initialFilterState } from '../_shared/filter/store';


export interface AuthState {
  auth: Models.IAuthenticationInfo;
}

export interface ConfigState {
  audienceSegments: Models.IAudienceSegment[];
  appointmentDispositionActivityId: number;
  brands: Models.IBrand[];
  campaignTypes: Models.ICampaignType[];
  currentSalesMonth: Date;
  channels: Models.IChannel[];
  clientTimeZone: string;
  dateMonths: Models.DatePeriodInfoModel[];
  dateQuarters: Models.DatePeriodInfoModel[];
  dealTypes: Models.IDealType[];
  dayOfSalesMonth: number;
  chatProviders: Models.IProviderLookup[];
  tradeInProviders: Models.IProviderLookup[];
  dealerHierarchys: Models.IDealerHierarchyRegion[];
  dealerLookups: Models.IDealerLookup[];
  dealerGroupLookups: Models.IOrgLookup[];
  deviceTypes: Models.IDeviceType[];
  digAdProviders: Models.IProviderLookup[];
  journeySegments: Models.IJourneySegment[];
  leadGates: Models.ILeadGate[];
  leadTypes: Models.ILeadType[];
  lmaLookups: Models.IOrgLookup[];
  profitCenters: Models.IProfitCenter[];
  digAdPrograms: Models.IDigAdProgram[];
  providers: Models.IProviderLookup[];
  orgLookups: Models.IOrgLookup[];
  vehicleLookups: Models.IVehicleLookup[];
  reputationPlatforms: Models.IReputationPlatform[];
  reputationProviders: Models.IProviderLookup[];
  saleTypes: Models.ISaleType[];
  serviceSchedulerProviders: Models.IProviderLookup[];
  showInactiveDealerMetrics: boolean;
  socialMediaPlatforms: Models.ISocialMediaPlatform[];
  socialMediaProviders: Models.IProviderLookup[];
  sourceTypes: Models.ISourceType[];
  traitFilters: Models.ITraitFilter[];
  virtual20Lookups: Models.IOrgLookup[];
  vehicleMakes: Models.IVehicleMake[];
  vehicleClasses: string[];
  vehicleModels: Models.IVehicleModel[];
  vehicleTypes: Models.IVehicleType[];
  websiteProviders: Models.IProviderLookup[];
  error?: string | Error | HttpErrorResponse;
  dealerSizes: Models.IDealerSize[];
  roles: Models.IRole[];
  pages: Models.IPage[];
}

export interface LocaleState {
  locale: string;
  userDefaultLocale?: string;
}

export interface AppState {
  auth: AuthState;
  filters: FilterState;
  locale: LocaleState;
  router?: RouterReducerState<any>;
  config: Config;
}

const cachedUser = JSON.parse(localStorage.getItem('user'));

export const initialAuthState: AuthState = {
  auth: cachedUser
};

export const initialLocaleState: LocaleState = {
  locale: undefined
};

export const initialConfigState: ConfigState = {
  audienceSegments: [],
  appointmentDispositionActivityId: undefined,
  brands: [],
  campaignTypes: [],
  channels: [],
  chatProviders: [],
  clientTimeZone: undefined,
  currentSalesMonth: undefined,
  dateMonths: [],
  dateQuarters: [],
  dayOfSalesMonth: undefined,
  dealerGroupLookups: [],
  dealerHierarchys: [],
  dealerLookups: [],
  dealerSizes: [],
  dealTypes: [],
  deviceTypes: [],
  digAdPrograms: [],
  digAdProviders: [],
  journeySegments: [],
  leadTypes: [],
  leadGates: [],
  lmaLookups: [],
  orgLookups: [],
  pages: [],
  providers: [],
  profitCenters: [],
  reputationPlatforms: [],
  reputationProviders: [],
  roles: [],
  saleTypes: [],
  serviceSchedulerProviders: [],
  showInactiveDealerMetrics: undefined,
  sourceTypes: [],
  socialMediaPlatforms: [],
  socialMediaProviders: [],
  tradeInProviders: [],
  traitFilters: [],
  vehicleClasses: [],
  vehicleLookups: [],
  vehicleMakes: [],
  vehicleModels: [],
  vehicleTypes: [],
  virtual20Lookups: [],
  websiteProviders: [],
};

export const initialAppState: AppState = {
  auth: initialAuthState,
  config: initialConfigState,
  filters: initialFilterState,
  locale: initialLocaleState
};
