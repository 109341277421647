<div fxLayout="row" fxLayoutAlign="end center" *ngIf="config$ | async as config">
  <div class="date-label" *ngIf="modeDisplayValue">
    <!-- {{ getModeDisplayValue(config.selected) }} -->
    {{modeDisplayValue}}
  </div>
  <div>
    <mat-form-field floatLabel="never" class="date-select">
      <mat-label>{{startDateDisplay}} - {{endDateDisplay}}</mat-label>
      <mat-select [matMenuTriggerFor]="dates" [value]="config.selected"></mat-select>
      <mat-menu #dates="matMenu" class="date-menu">
        <div [class]="!!reportConfig.salesCalendarToggleDisabled ? 'date-menu-items-no-calendar-select' : 'date-menu-items'">
          <div class="date-toggle" *ngIf="!reportConfig.salesCalendarToggleDisabled"
               style="width: 225px; padding:5px; font-size: smaller; position: relative; z-index:0; background: #fff; margin-top: 0px;">
            <mat-slide-toggle [disabled]="disableCalendarModeToggle" [checked]="salesMonthsEnabled$ | async" (click)="$event.stopPropagation()"
                              (change)="toggleSalesMonthEnabled($event)">{{'Sales Months Enabled' | translate: locale}}</mat-slide-toggle>
            <mat-divider class="date-toggle-divider"></mat-divider>
          </div>
          <button mat-menu-item [matMenuTriggerFor]="quarters" *ngIf="!!reportConfig.quarterDateModeEnabled" [value]="0" (click)="$event.stopPropagation()">{{'Quarters' | translate: locale}}</button>
          <mat-menu #quarters="matMenu" xPosition="after" [overlapTrigger]="false">
            <button mat-menu-item *ngFor="let item of config.quarters" (click)="applyDateRange(item)">{{item.displayName}}</button>
          </mat-menu>
          <button mat-menu-item *ngFor="let item of config.items" (click)="applyDateRange(item)">{{item.displayName | translate: locale}}</button>
          <button mat-menu-item [matMenuTriggerFor]="customDates" *ngIf="config.showCustom" [value]="0" (click)="$event.stopPropagation()">{{'Custom' | translate : locale}}</button>
          <mat-menu #customDates="matMenu" xPosition="after" [overlapTrigger]="false">
            <div [disableRipple]="true" mat-menu-item style="height:161px !important; width:234px !important;">
              <mat-form-field>
                <input #startDate matInput [matDatepicker]="startDatePicker" placeholder="{{'Start' | translate: locale}}"
                       (click)="$event.stopPropagation()" [value]="config.selected.startDate">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
              <br />
              <mat-form-field>
                <input #endDate matInput [matDatepicker]="endDatePicker" placeholder="{{'End' | translate: locale}}"
                       (click)="$event.stopPropagation()" [value]="config.selected.endDate">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
              <br />
              <span style="width:100%; display: inline-block; text-align: center;">
                <button mat-button (click)="applyCustomDateRange(startDate.value, endDate.value)">{{'Apply' | translate: locale}}</button>
                <button mat-button>{{'Cancel' | translate: locale}}</button>
              </span>
            </div>
          </mat-menu>
        </div>
      </mat-menu>
    </mat-form-field>

    <ng-container *ngIf="dateFilter$ | async as dateFilter">
      <button mat-icon-button class="lock-button" (click)="toggleDateLock(dateFilter)">
        <mat-icon class="lock-button-icon" [class.locked]="dateFilter.locked">
          {{dateFilter.locked ? 'lock' : 'lock_open'}}</mat-icon>
      </button>
    </ng-container>
  </div>
</div>
