import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { SeriesOptionsType } from 'highcharts';
import { ChartService } from '../../chart/chart.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'kpi-multi-line-chart',
  templateUrl: './kpi-multi-line-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-multi-line-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiMultiLineChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  currentChart: Chart;
  chartTitle: string = '';
  locale: any;
  colors = [];
  componentActive: boolean = true;

  constructor(
    private chartService: ChartService
  ) {

  }

  ngOnInit() {
    this.chartService.reflowChart$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 200);
      });

    this.colors = this.settings.chartColors ?? [this.settings.chartPrimaryColor, this.settings.chartSecondaryColor];

    let seriesData = [];

    const metricIndexMapping = this.dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof this.dataSet.columns[0] & { index: number }>);

    // Generate x labels
    const xAxisLables = this.dataSet.rows.map(m => m[metricIndexMapping[this.settings.dimensionName].index].label)

    // Generate points
    let colorIndex = 0

    this.selectedMetrics.forEach(metric => {
      const data = this.dataSet.rows.map(m => {
        const value = m[metricIndexMapping[metric].index].value;
        const label = m[metricIndexMapping[metric].index].label;

        return {
          y: value,
          name: label ? label : value
        };
      });

      seriesData.push({
        name: metricIndexMapping[metric].displayName,
        data: data
      });

      // generate title
      if (this.chartTitle != '') this.chartTitle += ' vs. ';
      this.chartTitle += metricIndexMapping[metric].displayName;
    });

    // Generate y axis options and link them to series
    let yAxisOptions = this.selectedMetrics.map((metric, index) => {
      return {
        title: { text: metricIndexMapping[metric].displayName },
        opposite: index % 2 === 1, // Put every second axis on the right side
        // labels: {
        //   formatter: function () {
        //     // If the metric kind is a 'ratio', format as a percentage
        //     if (metricIndexMapping[metric].kind == "ratio") {
        //       return (this.value * 100).toFixed(2) + '%';
        //     } else {
        //       return (this.value / 1000).toLocaleString() + 'k';
        //     }
        //   }
        // }
      };
    });

    // Link series to the corresponding y-axis
    seriesData.forEach((series, index) => {
      series.yAxis = index; // Link this series to its y-axis
    });

    // Generate the actual chart
    this.currentChart = this.generateChart(seriesData, xAxisLables, yAxisOptions);
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(seriesData: any[], xAxisLables: any[], yAxisOptions: any[]) {
    return new Chart({
      chart: {
        type: 'spline'
      },
      credits: { enabled: false },
      colors: this.colors,
      title: { text: this.chartTitle },
      xAxis: {
        allowDecimals: false,
        categories: xAxisLables
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let s = '';

          for (let i = 0; i < this.points.length; i++) {
            const myPoint = this.points[i];
            s += '<br/>' + myPoint.series.name + ': ';

            // If 'name' property is defined, show it. Otherwise, show 'y' property.
            s += myPoint.point.name ? myPoint.point.name : myPoint.y;
          }
          return s;
        },
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      yAxis: yAxisOptions,
      series: seriesData
    });
  }
}
